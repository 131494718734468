import { v7 } from "uuid";
import * as yup from "yup";
/**
 * @typedef {Object} CpfValidationSchema
 * @property {string} cpfName - CPF name is required.
 * @property {string} cpfWages - CPF wages is required.
 * @property {string} cpfStartDate - CPF start date is required.
 * @property {string} cpfEndDate - CPF end date is required.
 * @property {boolean} cpfStatus - CPF status is required.
 * @property {boolean} [cpfMaxSelect] - Optional maximum select for CPF.
 * @property {Array<Object>} cpfList - CPF list is required.
 * @property {Object} cpfList.items - An item in the CPF list.
 * @property {number} cpfList.items.employeesAgeFrom - Employee from age is required.
 * @property {number} cpfList.items.employeesAgeTo - Employee to age is required.
 * @property {number} cpfList.items.employerContribution - Employer contribution is required.
 * @property {number} cpfList.items.employeeContribution - Employee contribution is required.
 * @property {number} cpfList.items.totalContribution - Total contribution is required.
 */

export const CpfbSchema = yup.object().shape({
  cpfName: yup.string().required("CPF name is required"),
  cpfWages: yup.string().required("CPF wages is required"),
  cpfStartDate: yup.string().required("CPF start date is required"),
  cpfEndDate: yup.string().required("CPF end date is required"),
  cpfStatus: yup.boolean().required("CPF status is required"),
  cpfMaxSelect: yup.boolean(),
  cpfList: yup
    .array()
    .of(
      yup.object().shape({
        employeesAgeFrom: yup
          .number()
          .required("Employee from age is required"),
        employeesAgeTo: yup.number().required("Employee to age is required"),
        employerContribution: yup
          .number()
          .required("Employer contribution is required"),
        employeeContribution: yup
          .number()
          .required("Employee contribution is required"),
        totalContribution: yup
          .number()
          .required("Total contribution is required"),
      }),
    )
    .required("CPF list is required"),
});

//
// shg
//

/**
 * @typedef {Object} SettingsSchema
 * @property {number} grossWagesFrom - The starting gross wages.
 * @property {number} grossWagesTo - The ending gross wages.
 * @property {number} minContribution - The minimum contribution.
 */

/**
 * Schema for validating the settings object.
 * @type {yup.ObjectSchema<SettingsSchema>}
 */
const settingsSchema = yup.object().shape({
  grossWagesFrom: yup.number().required("Gross wages from is required"),
  // grossWagesTo: yup.number().required("Gross wages to is required"),
  grossWagesTo: yup
    .number()
    .required("To (Gross Wages) is required")
    .test(
      "greater-than-from",
      "To (Gross Wages) must be greater than From (Gross Wages)",
      function (value) {
        const fromValue = Number(this.parent.grossWagesFrom);
        const toValue = Number(value);

        // console.log("From Value:", this.parent.grossWagesFrom);
        // console.log("To Value:", value);
        // console.log("Converted From:", fromValue);
        // console.log("Converted To:", toValue);
        // console.log("Validation Result:", toValue > fromValue);

        return toValue > fromValue; // Ensures 'To' is greater than 'From'
      },
    ),
  minContribution: yup.number().required("Minimum contribution is required"),
});

/**
 * @typedef {Object} PayrollShgSchema
 * @property {string} organizationID - The ID of the organization.
 * @property {string} shgName - The name of the SHG.
 * @property {Date} shgStartDate - The start date of the SHG.
 * @property {Date} shgEndDate - The end date of the SHG.
 * @property {boolean} shgStatus - The status of the SHG.
 * @property {boolean} shgMaxSelectCdac - Maximum select for CDAC.
 * @property {boolean} shgMaxSelectMbmf - Maximum select for MBMF.
 * @property {boolean} shgMaxSelectSinda - Maximum select for ECF.
 * @property {boolean} shgMaxSelectSinda - Maximum select for SINDA.
 * @property {SettingsSchema[]} cdacSettings - The settings for CDAC.
 * @property {SettingsSchema[]} mbmfSettings - The settings for MBMF.
 * @property {SettingsSchema[]} ecfSettings - The settings for ECF.
 * @property {SettingsSchema[]} sindaSettings - The settings for SINDA.
 */

/**
 * Schema for validating the Payroll SHG object.
 * @type {yup.ObjectSchema<PayrollShgSchema>}
 */
export const payrollShgSchema = yup.object().shape({
  shgName: yup.string().required("SHG name is required"),
  shgStartDate: yup.string().required("SHG start date is required"),
  shgEndDate: yup.string().required("SHG end date is required"),
  shgStatus: yup.boolean().required("SHG status is required"),
  shgMaxSelectCdac: yup.boolean().required("SHG max select CDAC is required"),
  shgMaxSelectMbmf: yup.boolean().required("SHG max select MBMF is required"),
  shgMaxSelectEcf: yup.boolean().required("SHG max select ECF is required"),
  shgMaxSelectSinda: yup.boolean().required("SHG max select SINDA is required"),
  cdacSettings: yup.array().of(settingsSchema),
  mbmfSettings: yup.array().of(settingsSchema),
  ecfSettings: yup.array().of(settingsSchema),
  sindaSettings: yup.array().of(settingsSchema),
});
/**
 * @typedef {Object} Item
 * @property {string} name - The name of the item.
 * @property {string} type - The type/category of the item.
 * @property {string} amountType - The type for the amount (e.g., "kg", "liters").
 * @property {number} amount - The quantity of the item (must be a non-negative number).
 * @property {string} uId - A unique identifier for the item, generated using UUID v7.
 */

export const payrollType = yup.object().shape({
  name: yup.string().required("Name Component is required"),
  type: yup.string().required("Type is required"),
  amountType: yup.string().required("Amount Type is required"),
  amount: yup
    .number()
    .min(0, "Amount must be at least 0")
    .required("Amount is required"),
  uId: yup.string().default(() => v7()),
});
