import dayjs from "dayjs";

export const formatRupiah = (value = 0, type = `IDR`) => {
  if (!value && value !== 0) return "";

  return new Intl.NumberFormat(`id-ID`, {
    currency: type,
    style: "currency",
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  }).format(value);
};

export const formatCurrency = (value = 0, type = `IDR`, country = `id-ID`) => {
  if (!value && value !== 0) return "";

  return new Intl.NumberFormat(country, {
    currency: type,
    style: "currency",
  }).format(value);
};

/**
 * Converts a currency string from any locale to a number.
 * Supports various currency formats, including Rupiah (IDR) and other locales.
 * @param {string|number} currencyString - The currency string to be converted.
 * @return {number} - The converted number.
 */
export function currencyToNumber(currencyString) {
  // Ensure the input is a string
  if (typeof currencyString !== "string") {
    currencyString = String(currencyString); // Convert to string if it's a number or other type
  }

  // Step 1: Remove any currency symbols (e.g., Rp, $, €, etc.) and non-numeric characters except for dots, commas, and minus signs
  const sanitizedString = currencyString
    .replace(/[^\d.,-]/g, "") // Remove everything except digits, commas, dots, and minus sign
    .replace(/\./g, "") // Remove all dots (thousand separators for IDR)
    .replace(/,(\d{1,2})$/, ".$1"); // Replace comma with dot for decimal separator

  // Step 2: Convert the cleaned and normalized string to a float
  const result = parseFloat(sanitizedString);

  // Step 3: Return the result, and if NaN (conversion failed), return 0
  return isNaN(result) ? 0 : result;
}

// export function cleanNumberInput(value) {
//   // Remove all characters except numbers and dots
//   value = value.replace(/[^0-9.]/g, "");

//   // Remove leading zeros except if it's followed by a dot or if it's the only zero
//   value = value.replace(/^0+(?=\d)/, "");

//   return value;
// }
export function cleanNumberInput(value) {
  // Remove dollar sign and any non-numeric characters except dot
  value = value.replace(/[$,]/g, "").replace(/[^0-9.]/g, "");

  // Remove leading zeros unless followed by a dot or if it's the only zero
  value = value.replace(/^0+(?=\d)/, "");

  return value;
}

/**
 * Calculate the discount percentage based on original and final prices.
 *
 * @param {number} originalPrice - The original price of the item.
 * @param {number} finalPrice - The final price of the item after discount.
 * @returns {string} - The discount percentage as a string without decimal places.
 */
export function calculateDiscountPercentageFromPrices(
  originalPrice,
  finalPrice,
) {
  const discountAmount = originalPrice - finalPrice;

  const discountPercentage = (discountAmount / originalPrice) * 100;

  return discountPercentage.toFixed(0);
}

/**
 * Converts a currency string to a decimal number.
 * This function handles multiple currencies by accepting a locale and currency code.
 *
 * @param {string} currencyString - The currency string to convert.
 * @param {string} locale - The locale used to format the currency (e.g., "en-US", "de-DE").
 * @returns {number} The converted decimal number.
 */
export function convertCurrencyToNumber(currencyString, locale = "en-US") {
  // Create a NumberFormat object for the given locale
  const format = new Intl.NumberFormat(locale, {
    style: "currency",
    currency: "USD", // Currency code is not important for parsing
    currencyDisplay: "symbol",
    minimumFractionDigits: 2,
  });

  // Retrieve formatting parts to determine the decimal and grouping separators
  const parts = format.formatToParts(1234.56);
  const decimalSeparator = parts.find((part) => part.type === "decimal").value;
  const groupSeparator = parts.find((part) => part.type === "group").value;

  // Replace the group separator with an empty string and the decimal separator with a period
  const cleanedString = currencyString
    .replace(new RegExp(`\\${groupSeparator}`, "g"), "")
    .replace(new RegExp(`\\${decimalSeparator}`), ".")
    .replace(/[^0-9.-]+/g, "");

  // Convert the cleaned string to a number
  const number = parseFloat(cleanedString);

  // Return the converted number or 0 if it's NaN
  return isNaN(number) ? 0 : number;
}
/**
 * Converts minutes to hours in decimal format.
 *
 * @param {number} minutes - The total minutes to convert.
 * @returns {number} The equivalent hours in decimal format.
 */
export function convertMinutesToHours(minutes) {
  return minutes / 60;
}
/**
 * Function to calculate age based on the given birth date.
 * @param {string} birthDateString - The birth date in the format 'YYYY-MM-DD'.
 * @returns {number} - The calculated age.
 */

/**
 * Function to calculate age based on the given birth date using Day.js.
 * @param {string} birthDateString - The birth date in ISO format, e.g., 'YYYY-MM-DDTHH:mm:ss.sssZ'.
 * @returns {number} - The calculated age.
 */
export function calculateAge(birthDateString) {
  const today = dayjs();
  const birthDate = dayjs(birthDateString);

  let age = today.year() - birthDate.year();

  // Adjust the age if the birth date has not been reached yet this year
  if (
    today.month() < birthDate.month() ||
    (today.month() === birthDate.month() && today.date() < birthDate.date())
  ) {
    age--;
  }

  return age;
}
/**
 * Function to calculate percentage of a given salary.
 * @param {number} salary - The salary amount.
 * @param {number} percentage - The percentage to calculate.
 * @returns {number} - The calculated percentage of the salary.
 */
export function calculatePercentage(salary, percentage) {
  return (salary * percentage) / 100;
}
/**
 * Finds the percentage of a part from a whole.
 * @param {number} part - The part value (e.g., 10,000).
 * @param {number} whole - The whole value (e.g., 100,000).
 * @returns {number} The percentage.
 */
export function findPartPercentage(part, whole) {
  if (whole === 0) {
    throw new Error("The whole value cannot be zero.");
  }
  return (part / whole) * 100;
}

/**
 * Function to handle both zoom in and zoom out actions
 * @param {string} direction - "in" for zoom in, "out" for zoom out
 * @param {number} zoomLevel - The current zoom level
 * @param {function} setZoomLevel - Function to update the zoom level state
 * @param {function} setZoomPercentage - Function to update the zoom percentage state
 */
export function handleZoom(
  direction,
  zoomLevel,
  setZoomLevel,
  setZoomPercentage,
) {
  let newZoom;

  if (direction === "in") {
    newZoom = zoomLevel + 0.1;
  } else if (direction === "out") {
    newZoom = zoomLevel - 0.1;
  }

  // Update zoom level and percentage
  setZoomLevel(newZoom);
  setZoomPercentage(newZoom * 100);
}
