import ChevronRight from "assets/icons/ChevronRight";
import ChevronLeft from "assets/icons/ChevronLeft";
import ArchiveSVG from "assets/icons/ArchiveSVG";
import PaperSVG from "assets/icons/PaperSVG";
import { Button, BreadcrumbSteps } from "@bluesilodev/timhutcomponents";

const Steps = ({
  steps,
  labelSteps,
  setSteps,
  loading,
  handleSubmit,
  setStatus,
  handleNextStep,
}) => {
  return (
    <div className="w-[100%] grid grid-cols-3 gap-10 justify-between items-center">
      {steps > 1 && labelSteps.length > steps && (
        <Button
          className={"w-[200px]"}
          onClick={() => setSteps((prev) => prev - 1)}
          type={"submit"}
          label={
            <div className="flex px-4 gap-1">
              <div className={"mt-1"}>
                <ChevronLeft color={"#f97316"} />
              </div>
              <div>Back</div>
            </div>
          }
        />
      )}
      <div className="flex w-full mt-5 col-start-2">
        <BreadcrumbSteps
          currentStep={steps}
          // onStepClick={(d) => setSteps(d)}
          steps={labelSteps}
        />
      </div>
      {steps === 1 && (
        <div class="flex justify-end">
          <Button
            type={"submit"}
            onClick={handleNextStep}
            className={"w-[200px]"}
            label={
              <div className="flex px-4 gap-2 items-center justify-center">
                <div>Next</div>
                <div className={"mt-1"}>
                  <ChevronRight color={"#f97316"} />
                </div>
              </div>
            }
          />
        </div>
      )}
      {steps === 2 && (
        <div className={"flex gap-4"}>
          <Button
            type={"button"}
            onClick={() => {
              setStatus("draft");
              handleSubmit();
            }}
            className={"w-[200px]"}
            label={
              <div className="flex px-4 gap-2 items-center justify-center capitalize">
                <div>{loading ? "...loading" : "save to draft"} </div>
                <div className={"mt-1 "}>
                  <ArchiveSVG color={"#f97316"} />
                </div>
              </div>
            }
          />
          <Button
            type={"button"}
            onClick={() => {
              setStatus("paid");
              handleSubmit();
            }}
            className={"w-[200px]"}
            backgroundColor={"#DD7224"}
            label={
              <div className="flex px-4 gap-2 items-center justify-center capitalize text-white">
                <div>{loading ? "...loading" : "run payroll"} </div>
                <div className={"mt-1 "}>
                  <PaperSVG color={"#fff"} />
                </div>
              </div>
            }
          />
        </div>
      )}
    </div>
  );
};
export default Steps;
