import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  ModalDialog,
  InputText,
  InputSelect,
  InputNumber,
  SearchBox,
} from "@bluesilodev/timhutcomponents";
import { useAlertApi } from "services/alert-api";
import { PayrollComponentDataTable } from "components/common/customTables";
import FileIconSvg from "assets/icons/FileIconSVG/FileIconSvg";
import {
  PayrollComponentApi,
  useAddPayrollComponentMutation,
  useDeletePayrollComponentMutation,
  useFetchPayrollComponentQuery,
  useUpdatePayrollComponentMutation,
} from "store/api/payrollComponentApi";
import { v7 } from "uuid";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useDebounce } from "../../../utils/useDebounce";
import { payrollType } from "type/typeCpf";

const PayrollComponent = () => {
  const [isShow, setIsShow] = useState(false);
  const [isError, setIsError] = useState(false);
  const { currentRole } = useSelector((state) => state.loginSlice);
  const role = useMemo(() => {
    return currentRole || "employee";
  }, [currentRole]);

  const [search, setSearch] = useState("");
  const debouncedValue = useDebounce(search, 600);
  const [id, setId] = useState(null);
  const { data, error, isLoading } = useFetchPayrollComponentQuery(
    { search: debouncedValue },
    {
      refetchOnMountOrArgChange: true,
    },
  );
  const [requestComponent, resultComponent] = useAddPayrollComponentMutation();
  const [requestDeleteComponent] = useDeletePayrollComponentMutation();
  const [requestUpdateComponent, resultUpdateComponent] =
    useUpdatePayrollComponentMutation();
  const dispatch = useDispatch();
  const {
    handleBlur,
    handleChange,
    setFieldValue,
    touched,
    values,
    handleSubmit,
    setValues,
    errors,
    resetForm,
  } = useFormik({
    validationSchema: payrollType,
    initialValues: {
      name: "",
      type: "",
      amountType: "",
      amount: 0,
      uId: v7(),
    },
    onSubmit: async (values) => {
      if (
        values.name === "" ||
        values?.type === "" ||
        values?.amountType === "" ||
        values?.amount == 0
      ) {
        setIsError(true);
        return;
      }

      if (id) {
        await requestUpdateComponent({ id: id, ...values });
      } else {
        await requestComponent(values);
        setId(null);
      }
      setValues({
        name: "",
        type: "",
        amountType: 0,
        amount: 0,
        uId: v7(),
      });
      dispatch(PayrollComponentApi.util.invalidateTags(["payrollComponent"]));
      setIsShow(false);
    },
  });

  useAlertApi(resultComponent);

  const handleDelete = async (data) => {
    await requestDeleteComponent({ id: data?._id });
    await dispatch(
      PayrollComponentApi.util.invalidateTags(["payrollComponent"]),
    );
  };
  const handleEdit = (data) => {
    setId(data?._id);
    setValues({
      amount: data.amount,
      name: data.name,
      amountType: data?.amountType,
      type: data?.type,
    });
    setIsShow(true);
  };
  useEffect(() => {
    if (!isShow) {
      resetForm({
        values: {
          name: "",
          type: "",
          amountType: "",
          amount: 0,
          uId: v7(),
        },
      });
      setId(null);
    }
  }, [isShow, setValues]);

  useEffect(() => {
    if (resultUpdateComponent?.isError) {
      setIsError(true);
    }
  }, [resultUpdateComponent]);
  return (
    <>
      <div className="w-full mt-5 h-max">
        <div className="flex justify-between w-full">
          <div className="w-[100%] flex flex-row gap-5 justify-end">
            <SearchBox
              className={"w-[308px]"}
              placeholder="Search"
              value={search}
              onChange={(d) => {
                setSearch(d?.target?.value);
              }}
              title="CPF Contribution Name"
            />

            {role?.toLocaleLowerCase() === "admin" && (
              <Button
                className={"w-[270px]"}
                style="solid"
                onClick={() => setIsShow(true)}
                label={
                  <div className="flex gap-1 px-4">
                    <FileIconSvg color={"white"} />
                    <div>Create New Component</div>
                  </div>
                }
              />
            )}
          </div>
        </div>
        <div className="flex flex-col mt-6 p-1 gap-2 w-[100%]">
          <PayrollComponentDataTable
            status={role?.toLocaleLowerCase()}
            dataTable={data?.data ?? []}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
          />
        </div>
      </div>
      <ModalDialog
        show={isShow}
        className={"min-w-[1000px] w-[50vw] "}
        onClose={() => setIsShow(false)}
        title={`${id ? "Edit" : "Create"} New Component`}
        children={
          <div className="flex-row w-full">
            <div className="grid grid-cols-2 gap-2 mb-5">
              <div>
                <InputText
                  title="Component Name"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name={"name"}
                  id={"name"}
                  error={errors.name && touched.name && errors.name}
                  placeholder="Enter component name"
                />
              </div>
              <div>
                <InputSelect
                  title="Component Type"
                  id={"type"}
                  name="type"
                  setFieldValue={setFieldValue}
                  value={values.type}
                  onChange={handleChange}
                  error={errors.type && touched.type && errors.type}
                  onBlur={handleBlur}
                  options={[
                    {
                      label: "Earning",
                      value: "earning",
                    },
                    {
                      label: "Deductions",
                      value: "deductions",
                    },
                  ]}
                />
              </div>
            </div>
            <div className="flex mb-5 ">
              <div className="grid w-full grid-cols-2 gap-2">
                <div>
                  <InputSelect
                    title="Amount Type"
                    id={"amountType"}
                    name="amountType"
                    setFieldValue={setFieldValue}
                    value={values.amountType}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      errors.amountType &&
                      touched.amountType &&
                      errors.amountType
                    }
                    options={[
                      {
                        label: "Fixed",
                        value: "fixed",
                      },
                      {
                        label: "Percentage",
                        value: "percent",
                      },
                    ]}
                  />
                </div>
                <div className="flex">
                  <div className="w-[100%]">
                    <InputNumber
                      label="Amount"
                      name="amount"
                      value={values.amount}
                      onChange={(d) => {
                        setFieldValue("amount", d);
                      }}
                      error={errors.amount}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex w-full mb-5">
              <div className="grid w-full grid-cols-2 gap-2">
                <div className="flex w-full">
                  <Button
                    className={"w-full"}
                    onClick={() => setIsShow(false)}
                    label={
                      <div className="flex gap-1 px-4">
                        <div>Cancel</div>
                      </div>
                    }
                  />
                </div>
                <div className="flex w-full">
                  <Button
                    style="solid"
                    className={"w-full"}
                    onClick={() => handleSubmit()}
                    label={
                      <div className="flex gap-1 px-4">
                        <FileIconSvg color={"white"} />
                        <div>{id ? "Edit" : "Create"} New Component</div>
                      </div>
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        }
      />
    </>
  );
};

export default PayrollComponent;
