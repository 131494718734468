import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Button,
  Accordion,
  InputText,
  TextArea,
  ToggleSwitch,
  WidgetCard,
} from "@bluesilodev/timhutcomponents";
import SaveIconSVG from "assets/icons/SaveIconSVG/SaveIconSVG";
import { useAlertApi } from "services/alert-api";
import {
  useAddClaimsTypeMutation,
  useUpdateClaimsTypeMutation,
} from "store/api/claimsTypeApi";
import { v7 } from "uuid";
import { useFormik } from "formik";
import { validationClaimType } from "type/typeClaims";

const AddEditClaimsType = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;

  const {
    errors,
    setFieldValue,
    setValues,
    handleSubmit,
    handleChange,
    values,
    touched,
    handleBlur,
  } = useFormik({
    initialValues: {
      title: "",
      description: "",
      isActive: true,
      uId: v7(),
      isEdit: false,
    },
    validationSchema: validationClaimType,
    onSubmit: (values) => {
      if (values?.isEdit === true) {
        requestUpdateClaimsType(values).then((res) => {
          if (res.data.data) {
            navigate("/claims-type");
          }
        });
      } else {
        requestClaimsType(values).then((res) => {
          if (res.data.data) {
            navigate("/claims-type");
          }
        });
      }
    },
  });

  const [requestClaimsType, resultClaimsType] = useAddClaimsTypeMutation();
  const [requestUpdateClaimsType, resultUpdateClaimsType] =
    useUpdateClaimsTypeMutation();

  useAlertApi(resultClaimsType);
  useAlertApi(resultUpdateClaimsType);

  useEffect(() => {
    if (state) {
      setValues({
        id: state?._id,
        title: state?.title,
        description: state?.description,
        isActive: state?.isActive,
        uId: state?.uId,
        isEdit: true,
      });
    }
  }, [state]);

  return (
    <div className="flex-col w-full mt-5 ">
      <div className="w-[100%]  flex justify-end">
        <Button
          style="solid"
          onClick={() => handleSubmit()}
          label={
            <div className="flex gap-1 px-4">
              <SaveIconSVG color={"white"} />
              <div>{values?.isEdit === true ? "Update" : "Save"}</div>
            </div>
          }
        />
      </div>
      <div className="flex w-full mt-5 ">
        <Accordion
          title={"Claims Type"}
          children={
            <div className="mt-5 w-[60%]">
              <InputText
                id={"title"}
                name={"title"}
                onChange={handleChange}
                value={values?.title}
                title="Claims Title"
                placeholder="Enter claims title"
                onBlur={handleBlur}
                error={errors.title && touched.title && errors.title}
              />

              <div className="flex mt-6 "></div>
              <TextArea
                label="Type Description"
                placeholder="Enter type description"
                rows={6}
                value={values?.description}
                id="description"
                onChange={(e) => {
                  setFieldValue("description", e?.target.value);
                }}
                onBlur={handleBlur}
              />
              {errors.description && touched.description && (
                <div className="mt-2 text-red-400 capitalize">
                  {errors.description}
                </div>
              )}
              <div className="flex mt-6 "></div>
              <WidgetCard
                height="60px"
                onClick={() => {}}
                radius="lg"
                width="150px"
                className="bottom border-[1px] rounded-[10px] border-black"
              >
                <div className="flex flex-col items-center justify-center">
                  <ToggleSwitch
                    value={values?.isActive}
                    onChange={(e) => {
                      setFieldValue("isActive", !values?.isActive);
                    }}
                    name="Active"
                    label={values?.isActive ? "Active" : "Disabled"}
                  />
                </div>
              </WidgetCard>
            </div>
          }
        />
      </div>
    </div>
  );
};

export default AddEditClaimsType;
