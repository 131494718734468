import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Accordion,
  InputText,
  InputDate,
  ToggleSwitch,
  ModalDialog,
} from "@bluesilodev/timhutcomponents";
import SaveIconSVG from "assets/icons/SaveIconSVG/SaveIconSVG";

import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { useAlertApi } from "services/alert-api";
import UserAddIcon from "assets/icons/UserAddSVG/UserAdd";
import { parse } from "date-fns";
import dayjs from "dayjs";
import {
  FwlConstructionDataTable,
  FwlManufacturingDataTable,
  FwlMarineDataTable,
  FwlProcessDataTable,
  FwlServiceDataTable,
} from "components/common/customTables";
import { useAddSdlMutation, useUpdateSdlMutation } from "store/api/sdlApi";
import {
  constructionSchema,
  manufactureSchema,
  payrollFwlSchema,
} from "type/typeFwl";
import { useAddFwlMutation, useUpdateFwlMutation } from "store/api/fwl";
/**
 * Modal component construction for adding or editing data.
 *
 * @param {Object} props - The properties object.
 * @param {function(*):*} [props.setIsShow] - Function to toggle visibility or set a flag based on the provided data. Defaults to a function returning the input data.
 * @param {function(*):*} [props.save] - Function to save the provided data. Defaults to a function returning the input data.
 * @param {Object} [props.edit] - The edit object containing employee information and financial data. Defaults to an empty employee with default values.
 * @param {string} [props.edit.employee=""] - The name of the employee.
 * @param {number} [props.edit.twm=0] - Total work months or another related metric.
 * @param {number} [props.edit.sdlPayable=0] - SDL payable or other financial metric.
 *
 * @returns {JSX.Element} The rendered modal component.
 */
const ModalAddEditConstruction = ({
  setIsShow = (data) => data,
  save = (data) => data,
  edit = { tier: "", monthly: 0, daily: 0 },
}) => {
  const {
    handleChange,
    setFieldValue,
    values,
    handleSubmit,
    errors,
    resetForm,
    setValues,
  } = useFormik({
    initialValues: {
      tier: "",
      monthly: 0,
      daily: 0,
    },
    validationSchema: constructionSchema,
    onSubmit: async (values) => {
      try {
        save(values);
        setIsShow(false);
        resetForm();
      } catch (error) {
        console.error("error", error);
      }
    },
  });

  useEffect(() => {
    resetForm();
  }, []);
  useEffect(() => {
    if (edit) {
      setValues(edit);
    }
  }, [edit]);
  return (
    <div className="grid items-center grid-cols-2 gap-4 my-5 ">
      <InputText
        value={values.tier}
        error={errors.tier}
        setFieldValue={setFieldValue}
        onChange={handleChange}
        name={"tier"}
        id={"tier"}
        title="Tier Name"
        placeholder="Enter tier name"
      />
      <InputText
        value={values.monthly}
        error={errors.monthly}
        setFieldValue={setFieldValue}
        onChange={handleChange}
        name={"monthly"}
        id={"monthly"}
        type={"number"}
        title="Monthly ($$)"
      />
      <InputText
        value={values.daily}
        error={errors.daily}
        setFieldValue={setFieldValue}
        onChange={handleChange}
        name={"daily"}
        id={"daily"}
        type={"number"}
        title="Daily ($$)"
      />
      <div className="flex mb-5 col-span-full">
        <div className="inline-flex gap-2">
          <div className="flex w-[480px]">
            <Button
              className={"w-[480px]"}
              onClick={() => setIsShow(false)}
              label={
                <div className="flex gap-1 px-4">
                  <div>Cancel</div>
                </div>
              }
            />
          </div>
          <div className="flex w-[480px]">
            <Button
              className={"w-[480px]"}
              style="solid"
              onClick={() => handleSubmit()}
              label={"Create Construction"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

/**
 * Modal component manufacturing for adding or editing data.
 *
 * @param {Object} props - The properties object.
 * @param {function(*):*} [props.setIsShow] - Function to toggle visibility or set a flag based on the provided data. Defaults to a function returning the input data.
 * @param {function(*):*} [props.save] - Function to save the provided data. Defaults to a function returning the input data.
 * @param {Object} [props.edit] - The edit object containing employee information and financial data. Defaults to an empty employee with default values.
 * @param {string} [props.edit.employee=""] - The name of the employee.
 * @param {number} [props.edit.twm=0] - Total work months or another related metric.
 * @param {number} [props.edit.sdlPayable=0] - SDL payable or other financial metric.
 *
 * @returns {JSX.Element} The rendered modal component.
 */
const ModalAddEditManufacturing = ({
  setIsShow = (data) => data,
  save = (data) => data,
  edit = {
    quote: "",
    monthlyBasicSkilled: 0,
    dailyBasicSkilled: 0,
    monthlyHigherSkilled: 0,
    dailyHigherSkilled: 0,
  },
}) => {
  const {
    handleChange,
    setFieldValue,
    values,
    handleSubmit,
    errors,
    resetForm,
    setValues,
  } = useFormik({
    initialValues: {
      quote: "",
      monthlyBasicSkilled: 0,
      dailyBasicSkilled: 0,
      monthlyHigherSkilled: 0,
      dailyHigherSkilled: 0,
    },
    validationSchema: manufactureSchema,
    onSubmit: async (values) => {
      try {
        save(values);
        setIsShow(false);
        resetForm();
      } catch (error) {
        console.error("error", error);
      }
    },
  });
  useEffect(() => {
    console.log("tes", errors);
  }, [errors]);
  useEffect(() => {
    resetForm();
  }, []);
  useEffect(() => {
    if (edit) {
      setValues(edit);
    }
  }, [edit]);
  return (
    <div className="grid items-center grid-cols-2 gap-4 my-5 ">
      <InputText
        value={values.quote}
        error={errors.quote}
        setFieldValue={setFieldValue}
        onChange={handleChange}
        name={"quote"}
        id={"quote"}
        title="Quote"
        placeholder="Enter quote"
      />

      <InputText
        value={values.monthlyBasicSkilled}
        error={errors.monthlyBasicSkilled}
        setFieldValue={setFieldValue}
        onChange={handleChange}
        type={"number"}
        name={"monthlyBasicSkilled"}
        id={"monthlyBasicSkilled"}
        title="Monthly Basic Skilled"
      />

      <InputText
        value={values.dailyBasicSkilled}
        error={errors.dailyBasicSkilled}
        setFieldValue={setFieldValue}
        type={"number"}
        onChange={handleChange}
        name={"dailyBasicSkilled"}
        id={"dailyBasicSkilled"}
        title="Daily Basic Skilled"
      />

      <InputText
        value={values.monthlyHigherSkilled}
        error={errors.monthlyHigherSkilled}
        setFieldValue={setFieldValue}
        type={"number"}
        onChange={handleChange}
        name={"monthlyHigherSkilled"}
        id={"monthlyHigherSkilled"}
        title="Monthly Higher Skilled"
      />

      <InputText
        value={values.dailyHigherSkilled}
        error={errors.dailyHigherSkilled}
        setFieldValue={setFieldValue}
        type={"number"}
        onChange={handleChange}
        name={"dailyHigherSkilled"}
        id={"dailyHigherSkilled"}
        title="Daily Higher Skilled"
      />

      <div className="flex mb-5 col-span-full">
        <div className="inline-flex gap-2">
          <div className="flex w-[480px]">
            <Button
              className={"w-[480px]"}
              onClick={() => setIsShow(false)}
              label={
                <div className="flex gap-1 px-4">
                  <div>Cancel</div>
                </div>
              }
            />
          </div>
          <div className="flex w-[480px]">
            <Button
              className={"w-[480px]"}
              style="solid"
              onClick={() => handleSubmit()}
              label={"Create Manufacturing"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

/**
 * Modal component manufacturing for adding or editing data.
 *
 * @param {Object} props - The properties object.
 * @param {function(*):*} [props.setIsShow] - Function to toggle visibility or set a flag based on the provided data. Defaults to a function returning the input data.
 * @param {function(*):*} [props.save] - Function to save the provided data. Defaults to a function returning the input data.
 * @param {Object} [props.edit] - The edit object containing employee information and financial data. Defaults to an empty employee with default values.
 * @param {string} [props.edit.employee=""] - The name of the employee.
 * @param {number} [props.edit.twm=0] - Total work months or another related metric.
 * @param {number} [props.edit.sdlPayable=0] - SDL payable or other financial metric.
 *
 * @returns {JSX.Element} The rendered modal component.
 */
const ModalAddEditService = ({
  setIsShow = (data) => data,
  save = (data) => data,
  edit = {
    quote: "",
    monthlyBasicSkilled: 0,
    dailyBasicSkilled: 0,
    monthlyHigherSkilled: 0,
    dailyHigherSkilled: 0,
  },
}) => {
  const {
    handleChange,
    setFieldValue,
    values,
    handleSubmit,
    errors,
    resetForm,
    setValues,
  } = useFormik({
    initialValues: {
      quote: "",
      monthlyBasicSkilled: 0,
      dailyBasicSkilled: 0,
      monthlyHigherSkilled: 0,
      dailyHigherSkilled: 0,
    },
    validationSchema: manufactureSchema,
    onSubmit: async (values) => {
      try {
        save(values);
        setIsShow(false);
        resetForm();
      } catch (error) {
        console.error("error", error);
      }
    },
  });
  useEffect(() => {
    console.log("tes", errors);
  }, [errors]);
  useEffect(() => {
    resetForm();
  }, []);
  useEffect(() => {
    if (edit) {
      setValues(edit);
    }
  }, [edit]);
  return (
    <div className="grid items-center grid-cols-2 gap-4 my-5 ">
      <InputText
        value={values.quote}
        error={errors.quote}
        setFieldValue={setFieldValue}
        onChange={handleChange}
        name={"quote"}
        id={"quote"}
        title="Quote"
        placeholder="Enter quote"
      />

      <InputText
        value={values.monthlyBasicSkilled}
        error={errors.monthlyBasicSkilled}
        setFieldValue={setFieldValue}
        onChange={handleChange}
        type={"number"}
        name={"monthlyBasicSkilled"}
        id={"monthlyBasicSkilled"}
        title="Monthly Basic Skilled"
      />

      <InputText
        value={values.dailyBasicSkilled}
        error={errors.dailyBasicSkilled}
        setFieldValue={setFieldValue}
        type={"number"}
        onChange={handleChange}
        name={"dailyBasicSkilled"}
        id={"dailyBasicSkilled"}
        title="Daily Basic Skilled"
      />

      <InputText
        value={values.monthlyHigherSkilled}
        error={errors.monthlyHigherSkilled}
        setFieldValue={setFieldValue}
        type={"number"}
        onChange={handleChange}
        name={"monthlyHigherSkilled"}
        id={"monthlyHigherSkilled"}
        title="Monthly Higher Skilled"
      />

      <InputText
        value={values.dailyHigherSkilled}
        error={errors.dailyHigherSkilled}
        setFieldValue={setFieldValue}
        type={"number"}
        onChange={handleChange}
        name={"dailyHigherSkilled"}
        id={"dailyHigherSkilled"}
        title="Daily Higher Skilled"
      />

      <div className="flex mb-5 col-span-full">
        <div className="inline-flex gap-2">
          <div className="flex w-[480px]">
            <Button
              className={"w-[480px]"}
              onClick={() => setIsShow(false)}
              label={
                <div className="flex gap-1 px-4">
                  <div>Cancel</div>
                </div>
              }
            />
          </div>
          <div className="flex w-[480px]">
            <Button
              className={"w-[480px]"}
              style="solid"
              onClick={() => handleSubmit()}
              label={"Create Manufacturing"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
/**
 * Modal component manufacturing for adding or editing data.
 *
 * @param {Object} props - The properties object.
 * @param {function(*):*} [props.setIsShow] - Function to toggle visibility or set a flag based on the provided data. Defaults to a function returning the input data.
 * @param {function(*):*} [props.save] - Function to save the provided data. Defaults to a function returning the input data.
 * @param {Object} [props.edit] - The edit object containing employee information and financial data. Defaults to an empty employee with default values.
 * @param {string} [props.edit.employee=""] - The name of the employee.
 * @param {number} [props.edit.twm=0] - Total work months or another related metric.
 * @param {number} [props.edit.sdlPayable=0] - SDL payable or other financial metric.
 *
 * @returns {JSX.Element} The rendered modal component.
 */
const ModalAddEditProcess = ({
  setIsShow = (data) => data,
  save = (data) => data,
  edit = { tier: "", monthly: 0, daily: 0 },
}) => {
  const {
    handleChange,
    setFieldValue,
    values,
    handleSubmit,
    errors,
    resetForm,
    setValues,
  } = useFormik({
    initialValues: { tier: "", monthly: 0, daily: 0 },
    validationSchema: constructionSchema,
    onSubmit: async (values) => {
      try {
        save(values);
        setIsShow(false);
        resetForm();
      } catch (error) {
        console.error("error", error);
      }
    },
  });
  useEffect(() => {
    console.log("tes", errors);
  }, [errors]);
  useEffect(() => {
    resetForm();
  }, []);
  useEffect(() => {
    if (edit) {
      setValues(edit);
    }
  }, [edit]);
  return (
    <div className="grid items-center grid-cols-2 gap-4 my-5 ">
      <InputText
        value={values.tier}
        error={errors.tier}
        setFieldValue={setFieldValue}
        onChange={handleChange}
        name={"tier"}
        id={"tier"}
        title="Tier Name"
        placeholder="Enter tier name"
      />
      <InputText
        value={values.monthly}
        error={errors.monthly}
        setFieldValue={setFieldValue}
        onChange={handleChange}
        name={"monthly"}
        id={"monthly"}
        type={"number"}
        title="Monthly ($$)"
      />
      <InputText
        value={values.daily}
        error={errors.daily}
        setFieldValue={setFieldValue}
        onChange={handleChange}
        name={"daily"}
        id={"daily"}
        type={"number"}
        title="Daily ($$)"
      />
      <div className="flex mb-5 col-span-full">
        <div className="inline-flex gap-2">
          <div className="flex w-[480px]">
            <Button
              className={"w-[480px]"}
              onClick={() => setIsShow(false)}
              label={
                <div className="flex gap-1 px-4">
                  <div>Cancel</div>
                </div>
              }
            />
          </div>
          <div className="flex w-[480px]">
            <Button
              className={"w-[480px]"}
              style="solid"
              onClick={() => handleSubmit()}
              label={"Create Construction"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

/**
 * Modal component manufacturing for adding or editing data.
 *
 * @param {Object} props - The properties object.
 * @param {function(*):*} [props.setIsShow] - Function to toggle visibility or set a flag based on the provided data. Defaults to a function returning the input data.
 * @param {function(*):*} [props.save] - Function to save the provided data. Defaults to a function returning the input data.
 * @param {Object} [props.edit] - The edit object containing employee information and financial data. Defaults to an empty employee with default values.
 * @param {string} [props.edit.employee=""] - The name of the employee.
 * @param {number} [props.edit.twm=0] - Total work months or another related metric.
 * @param {number} [props.edit.sdlPayable=0] - SDL payable or other financial metric.
 *
 * @returns {JSX.Element} The rendered modal component.
 */
const ModalAddEditMarine = ({
  setIsShow = (data) => data,
  save = (data) => data,
  edit = { tier: "", monthly: 0, daily: 0 },
}) => {
  const {
    handleChange,
    setFieldValue,
    values,
    handleSubmit,
    errors,
    resetForm,
    setValues,
  } = useFormik({
    initialValues: { tier: "", monthly: 0, daily: 0 },
    validationSchema: constructionSchema,
    onSubmit: async (values) => {
      try {
        save(values);
        setIsShow(false);
        resetForm();
      } catch (error) {
        console.error("error", error);
      }
    },
  });

  useEffect(() => {
    resetForm();
  }, []);
  useEffect(() => {
    if (edit) {
      setValues(edit);
    }
  }, [edit]);
  return (
    <div className="grid items-center grid-cols-2 gap-4 my-5 ">
      <InputText
        value={values.tier}
        error={errors.tier}
        setFieldValue={setFieldValue}
        onChange={handleChange}
        name={"tier"}
        id={"tier"}
        title="Tier Name"
        placeholder="Enter tier name"
      />
      <InputText
        value={values.monthly}
        error={errors.monthly}
        setFieldValue={setFieldValue}
        onChange={handleChange}
        name={"monthly"}
        id={"monthly"}
        type={"number"}
        title="Monthly ($$)"
      />
      <InputText
        value={values.daily}
        error={errors.daily}
        setFieldValue={setFieldValue}
        onChange={handleChange}
        name={"daily"}
        id={"daily"}
        type={"number"}
        title="Daily ($$)"
      />
      <div className="flex mb-5 col-span-full">
        <div className="inline-flex gap-2">
          <div className="flex w-[480px]">
            <Button
              className={"w-[480px]"}
              onClick={() => setIsShow(false)}
              label={
                <div className="flex gap-1 px-4">
                  <div>Cancel</div>
                </div>
              }
            />
          </div>
          <div className="flex w-[480px]">
            <Button
              className={"w-[480px]"}
              style="solid"
              onClick={() => handleSubmit()}
              label={"Create Construction"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
const AddEditFwl = () => {
  const location = useLocation();
  const { state } = location;
  const params = useParams();
  /**
   * @typedef {Object} PayrollShgSchema
   * @property {import("type/typeCpf").PayrollShgSchema} data
   */
  /**
   * @type {PayrollShgSchema}
   */
  const [request, result] = useAddFwlMutation();
  const [requestUpdate, resultUpdate] = useUpdateFwlMutation();
  const [isShow, setIsShow] = useState(false);
  const [isShow2, setIsShow2] = useState(false);
  const [isShow3, setIsShow3] = useState(false);
  const [isShow4, setIsShow4] = useState(false);
  const [isShow5, setIsShow5] = useState(false);
  const [edit, setEdit] = useState({ id: null, data: null });
  const navigate = useNavigate();

  const {
    handleChange,
    setFieldValue,
    values,
    handleSubmit,
    errors,
    resetForm,
    setValues,
    handleBlur,
    touched,
  } = useFormik({
    initialValues: {
      fwlName: "",
      fwlStartDate: "",
      fwlEndDate: "",
      fwlStatus: false,
      constructionSettings: [],
      manufactureSettings: [],
      marineShipyardSettings: [],
      processSettings: [],
      serviceSettings: [],
    },
    validationSchema: payrollFwlSchema,
    onSubmit: async (values) => {
      try {
        const parseValues = { ...values };

        if (
          parseValues?.__v !== "" &&
          parseValues?.__v !== undefined &&
          params?.id
        ) {
          delete parseValues.__v;
        }
        if (parseValues?.createdAt && params?.id) {
          delete parseValues.createdAt;
        }
        if (parseValues?.updatedAt && params?.id) {
          delete parseValues.updatedAt;
        }
        parseValues.fwlStartDate = parse(
          parseValues?.fwlStartDate,
          "dd/MM/yyyy",
          new Date(),
        );
        parseValues.fwlEndDate = parse(
          parseValues?.fwlEndDate,
          "dd/MM/yyyy",
          new Date(),
        );

        params?.id
          ? await requestUpdate({ id: params?.id, ...parseValues })
          : await request(parseValues);
        navigate("/fwl");
      } catch (error) {
        console.error("error", error);
      }
    },
  });

  /**
   * @param {''salaryCeilingList' } status
   */

  const handleDelete = (id, status) => {
    switch (status) {
      case "construction":
        {
          const tempSdl = [...values.constructionSettings];
          if (id !== null && id !== undefined) {
            tempSdl.splice(id, 1);
          }
          setFieldValue("constructionSettings", tempSdl);
        }
        break;
      case "manufacturing":
        {
          const tempSdl = [...values.manufactureSettings];
          if (id !== null && id !== undefined) {
            tempSdl.splice(id, 1);
          }
          setFieldValue("manufactureSettings", tempSdl);
        }
        break;
      case "marine":
        {
          const tempSdl = [...values.marineShipyardSettings];
          if (id !== null && id !== undefined) {
            tempSdl.splice(id, 1);
          }
          setFieldValue("marineShipyardSettings", tempSdl);
        }
        break;
      case "process":
        {
          const tempSdl = [...values.processSettings];
          if (id !== null && id !== undefined) {
            tempSdl.splice(id, 1);
          }
          setFieldValue("processSettings", tempSdl);
        }
        break;
      case "service":
        {
          const tempSdl = [...values.serviceSettings];
          if (id !== null && id !== undefined) {
            tempSdl.splice(id, 1);
          }
          setFieldValue("serviceSettings", tempSdl);
        }
        break;
      default:
        break;
    }
  };
  /**
   * @function handleEdit
   * @param {import("type/typeCpf").EmployeeContribution} data
   */
  const handleEdit = (data, status) => {
    switch (status) {
      case "construction":
        setEdit(data);
        setIsShow(true);
        break;
      case "manufacturing":
        setEdit(data);
        setIsShow2(true);
        break;
      case "marine":
        setEdit(data);
        setIsShow3(true);
        break;
      case "process":
        setEdit(data);
        setIsShow4(true);
        break;
      case "service":
        setEdit(data);
        setIsShow5(true);
        break;
      default:
        break;
    }
  };
  useAlertApi(params?.id ? resultUpdate : result);
  useEffect(() => {
    if (params?.id) {
      setFieldValue("_id", params?.id);
    }
  }, [params]);
  useEffect(() => {
    if (state) {
      const data = state;
      resetForm();
      const date = {
        fwlStartDate: dayjs(data.fwlStartDate).format("DD/MM/YYYY"),
        fwlEndDate: dayjs(data.fwlEndDate).format("DD/MM/YYYY"),
      };
      setValues({
        ...data,
        ...date,
      });
    }
  }, [state]);

  return (
    <div className="w-full mt-5 space-y-4">
      <div className="w-[100%]  flex justify-end">
        <Button
          style="solid"
          onClick={() => handleSubmit()}
          label={
            <div className="flex gap-1 px-4">
              <SaveIconSVG color={"white"} />
              <div>{params?.id ? "Update" : "Save"}</div>
            </div>
          }
        />
      </div>
      <div className="flex w-full mt-5 ">
        <Accordion
          title={`Singapore Foreign Worker Levy Details`}
          children={
            <div className="grid grid-cols-2 gap-4 mt-5">
              <div className="col-span-full">
                <InputText
                  value={values.fwlName}
                  error={errors.fwlName && touched.fwlName && errors.fwlName}
                  setFieldValue={setFieldValue}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required
                  name={"fwlName"}
                  id={"fwlName"}
                  title="Singapore Foreign Worker Levy Name"
                  placeholder="Enter Singapore foreign worker levy name"
                />
              </div>

              <InputDate
                name={"fwlStartDate"}
                id={"fwlStartDate"}
                label="Start Date Period"
                setFieldValue={setFieldValue}
                value={values.fwlStartDate}
                errors={
                  errors.fwlStartDate &&
                  touched.fwlStartDate &&
                  errors.fwlStartDate
                }
                onChange={handleChange}
                required
                onBlur={handleBlur}
              />
              <InputDate
                name={"fwlEndDate"}
                id={"fwlEndDate"}
                label="End Date Period"
                errors={
                  errors.fwlEndDate && touched.fwlEndDate && errors.fwlEndDate
                }
                setFieldValue={setFieldValue}
                value={values.fwlEndDate}
                onChange={handleChange}
                required
                onBlur={handleBlur}
              />
              <ToggleSwitch
                value={values?.fwlStatus}
                onChange={(e) => {
                  setFieldValue("fwlStatus", !values.fwlStatus);
                }}
                name="Active"
                label="Active"
              />
            </div>
          }
        />
      </div>
      <div className="flex flex-col items-end">
        {/* Construction */}
        <Button
          onClick={() => setIsShow(true)}
          className={"w-[200px] mt-3 justify-self-end"}
          backgroundColor={"#DD7224"}
          label={
            <div className="flex gap-1 p-2 text-white capitalize">
              <UserAddIcon color={"#fff"} />
              <div>
                <div>Add Construction</div>
              </div>
            </div>
          }
        />
        <ModalDialog
          show={isShow}
          className={"w-[1000px]"}
          onClose={() => setIsShow(false)}
          title={`${edit?.data ? "Edit" : "Create"} Construction Sector`}
          children={
            <ModalAddEditConstruction
              edit={edit.data}
              save={(d) => {
                const temp = [...values.constructionSettings];
                if (edit?.id !== null && edit?.id >= 0) {
                  temp[edit?.id] = d;
                } else {
                  temp.push(d);
                }
                setFieldValue("constructionSettings", temp);
                setEdit({ id: null, data: null });
              }}
              setIsShow={setIsShow}
            />
          }
        />

        <Accordion
          title={`Construction sector`}
          children={
            <div>
              <FwlConstructionDataTable
                dataTable={values?.constructionSettings ?? []}
                handleDelete={(d) => handleDelete(d, "construction")}
                handleEdit={(d) => handleEdit(d, "construction")}
              />
            </div>
          }
        />
        {/* Manufacturing */}
        <Button
          onClick={() => setIsShow2(true)}
          className={"w-[200px] mt-3 justify-self-end"}
          backgroundColor={"#DD7224"}
          label={
            <div className="flex gap-1 p-2 text-white capitalize">
              <UserAddIcon color={"#fff"} />
              <div>
                <div>Add Manufacturing</div>
              </div>
            </div>
          }
        />
        <ModalDialog
          show={isShow2}
          className={"w-[1000px]"}
          onClose={() => setIsShow2(false)}
          title={`${edit?.data ? "Edit" : "Create"} Manufacture`}
          children={
            <ModalAddEditManufacturing
              edit={edit.data}
              save={(d) => {
                const temp = [...values.manufactureSettings];
                if (edit?.id !== null && edit?.id >= 0) {
                  temp[edit?.id] = d;
                } else {
                  temp.push(d);
                }
                setFieldValue("manufactureSettings", temp);
                setEdit({ id: null, data: null });
              }}
              setIsShow={setIsShow2}
            />
          }
        />
        <Accordion
          title={`Manufacturing sector`}
          children={
            <div>
              <FwlManufacturingDataTable
                dataTable={values?.manufactureSettings ?? []}
                handleDelete={(d) => handleDelete(d, "manufacturing")}
                handleEdit={(d) => handleEdit(d, "manufacturing")}
              />
            </div>
          }
        />
        {/* Marine */}
        <Button
          onClick={() => setIsShow3(true)}
          className={"w-[200px] mt-3 justify-self-end"}
          backgroundColor={"#DD7224"}
          label={
            <div className="flex gap-1 p-2 text-white capitalize">
              <UserAddIcon color={"#fff"} />
              <div>
                <div>Add Marine</div>
              </div>
            </div>
          }
        />
        <ModalDialog
          show={isShow3}
          className={"w-[1000px]"}
          onClose={() => setIsShow3(false)}
          title={`${edit?.data ? "Edit" : "Create"} Marine`}
          children={
            <ModalAddEditMarine
              edit={edit.data}
              save={(d) => {
                const temp = [...values.marineShipyardSettings];
                if (edit?.id !== null && edit?.id >= 0) {
                  temp[edit?.id] = d;
                } else {
                  temp.push(d);
                }
                setFieldValue("marineShipyardSettings", temp);
                setEdit({ id: null, data: null });
              }}
              setIsShow={setIsShow3}
            />
          }
        />
        <Accordion
          title={`Marine shipyard sector`}
          children={
            <div>
              <FwlMarineDataTable
                dataTable={values?.marineShipyardSettings ?? []}
                handleDelete={(d) => handleDelete(d, "marine")}
                handleEdit={(d) => handleEdit(d, "marine")}
              />
            </div>
          }
        />
        {/* Process */}
        <Button
          onClick={() => setIsShow4(true)}
          className={"w-[200px] mt-3 justify-self-end"}
          backgroundColor={"#DD7224"}
          label={
            <div className="flex gap-1 p-2 text-white capitalize">
              <UserAddIcon color={"#fff"} />
              <div>
                <div>Add Process</div>
              </div>
            </div>
          }
        />
        <ModalDialog
          show={isShow4}
          className={"w-[1000px]"}
          onClose={() => setIsShow4(false)}
          title={`${edit?.data ? "Edit" : "Create"} Process Sector`}
          children={
            <ModalAddEditProcess
              edit={edit.data}
              save={(d) => {
                const temp = [...values.processSettings];
                if (edit?.id !== null && edit?.id >= 0) {
                  temp[edit?.id] = d;
                } else {
                  temp.push(d);
                }
                setFieldValue("processSettings", temp);
                setEdit({ id: null, data: null });
              }}
              setIsShow={setIsShow4}
            />
          }
        />
        <Accordion
          title={`Process sector`}
          children={
            <div>
              <FwlProcessDataTable
                dataTable={values?.processSettings ?? []}
                handleDelete={(d) => handleDelete(d, "process")}
                handleEdit={(d) => handleEdit(d, "process")}
              />
            </div>
          }
        />
        {/* Service */}
        <Button
          onClick={() => setIsShow5(true)}
          className={"w-[200px] mt-3 justify-self-end"}
          backgroundColor={"#DD7224"}
          label={
            <div className="flex gap-1 p-2 text-white capitalize">
              <UserAddIcon color={"#fff"} />
              <div>
                <div>Add Service</div>
              </div>
            </div>
          }
        />
        <ModalDialog
          show={isShow5}
          className={"w-[1000px]"}
          onClose={() => setIsShow5(false)}
          title={`${edit?.data ? "Edit" : "Create"} Service`}
          children={
            <ModalAddEditService
              edit={edit.data}
              save={(d) => {
                const temp = [...values.serviceSettings];
                if (edit?.id !== null && edit?.id >= 0) {
                  temp[edit?.id] = d;
                } else {
                  temp.push(d);
                }
                setFieldValue("serviceSettings", temp);
                setEdit({ id: null, data: null });
              }}
              setIsShow={setIsShow5}
            />
          }
        />
        <Accordion
          title={`Service sector`}
          children={
            <div>
              <FwlServiceDataTable
                dataTable={values?.serviceSettings ?? []}
                handleDelete={(d) => handleDelete(d, "service")}
                handleEdit={(d) => handleEdit(d, "service")}
              />
            </div>
          }
        />
      </div>
    </div>
  );
};

export default AddEditFwl;
