import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Header, Sidebar } from "@bluesilodev/timhutcomponents";
import {
  Outlet,
  useLocation,
  matchPath,
  NavLink,
  useParams,
  useNavigate,
} from "react-router-dom";

import { setLogoutReducer, setRoleReducer } from "store/slice/userLoginSlice";
import { useFetchAppsQuery } from "store/api/commonApi";

import PayrollSVG from "assets/icons/PayrollSVG/PayrollSVG";
import RunPayrollSVG from "assets/icons/RunPayrollSVG/RunPayrollSVG";
import ReportsSVG from "assets/icons/ReportsSVG/ReportsSVG";
import PayrollReportSVG from "assets/icons/PayrollReportSVG/PayrollReportSVG";
import EmployeePaySliptSVG from "assets/icons/EmployeePayslipSVG/EmployeePayslipSVG";
import ChevronDownSVG from "assets/icons/ChevronDownSVG/ChevronDownSVG";
import PayrollSetupSVG from "assets/icons/PayrollSetupSVG/PayrollSetupSVG";
import PayrollComponentSVG from "assets/icons/PayrollComponentSVG/PayrollComponentSVG";
import PaymentScheduleSVG from "assets/icons/PaymentScheduleSVG/PaymentScheduleSVG";
import ClaimsTypeSVG from "assets/icons/ClaimsTypeSVG/ClaimsTypeSVG";
import { TicketSVG } from "assets/icons/TicketSVG";
import pkg from "../../package.json";

const reportsDropDown = (location) => {
  return (
    <div>
      <div
        className="flex items-center w-full gap-1 p-3 rounded-md hover:cursor-pointer"
        onClick={() => {
          const hidden = document
            .getElementById("reports-payroll")
            .classList.contains("hidden");
          if (hidden) {
            document
              .getElementById("reports-payroll")
              .classList.remove("hidden");
          } else {
            document.getElementById("reports-payroll").classList.add("hidden");
          }
        }}
      >
        <ReportsSVG color={"black"} />
        <h1 className="text-sm font-semibold">Reports</h1>
        <ChevronDownSVG />
      </div>

      <div id="reports-payroll" className={`flex flex-col gap-2 mt-2`}>
        <NavLink
          to={"/payroll-report"}
          className={`flex gap-1 rounded-md p-3 w-full items-center hover:scale-x-105 hover:cursor-pointer transition-all duration-300 ${
            location.pathname === "/payroll-report" &&
            "bg-orange-500 text-white w-full"
          }`}
        >
          <PayrollReportSVG
            color={`${
              location.pathname === "/payroll-report" ? "white" : "black"
            }`}
            className="ml-5"
          />
          <h1 className="text-sm font-semibold">Payroll Report</h1>
        </NavLink>
        <NavLink
          to={"/employee-payslip"}
          className={`flex gap-1 rounded-md p-3 w-full items-center hover:scale-x-105 hover:cursor-pointer transition-all duration-300 ${
            location.pathname === "/employee-payslip" &&
            "bg-orange-500 text-white w-full"
          }`}
        >
          <EmployeePaySliptSVG
            color={`${
              location.pathname === "/employee-payslip" ? "white" : "black"
            }`}
            className="ml-5"
          />
          <h1 className="text-sm font-semibold">Employee Payslip</h1>
        </NavLink>
      </div>
    </div>
  );
};
const reportsEmployeeDropDown = (location) => {
  return (
    <div>
      <div
        className="flex items-center w-full gap-1 p-3 rounded-md hover:cursor-pointer"
        onClick={() => {
          const hidden = document
            .getElementById("reports-payroll")
            .classList.contains("hidden");
          if (hidden) {
            document
              .getElementById("reports-payroll")
              .classList.remove("hidden");
          } else {
            document.getElementById("reports-payroll").classList.add("hidden");
          }
        }}
      >
        <ReportsSVG color={"black"} />
        <h1 className="text-sm font-semibold">Reports</h1>
        <ChevronDownSVG />
      </div>

      <div id="reports-payroll" className={`flex flex-col gap-2 mt-2`}>
        <NavLink
          to={"/employee-payslip"}
          className={`flex gap-1 rounded-md p-3 w-full items-center hover:scale-x-105 hover:cursor-pointer transition-all duration-300 ${
            location.pathname === "/employee-payslip" &&
            "bg-orange-500 text-white w-full"
          }`}
        >
          <EmployeePaySliptSVG
            color={`${
              location.pathname === "/employee-payslip" ? "white" : "black"
            }`}
            className="ml-5"
          />
          <h1 className="text-sm font-semibold">Employee Payslip</h1>
        </NavLink>
      </div>
    </div>
  );
};

const payrollslipDropDown = (location) => {
  return (
    <div>
      <div
        className="flex items-center w-full gap-1 p-3 rounded-md hover:cursor-pointer"
        onClick={() => {
          const hidden = document
            .getElementById("payroll-setup")
            .classList.contains("hidden");
          if (hidden) {
            document.getElementById("payroll-setup").classList.remove("hidden");
          } else {
            document.getElementById("payroll-setup").classList.add("hidden");
          }
        }}
      >
        <PayrollSetupSVG color={"black"} />
        <h1 className="text-sm font-semibold">Payroll Setup</h1>
        <ChevronDownSVG />
      </div>

      <div id="payroll-setup" className={`flex flex-col gap-2 mt-2`}>
        <NavLink
          to={"/payroll-component"}
          className={`flex gap-1 rounded-md p-3 w-full items-center hover:scale-x-105 hover:cursor-pointer transition-all duration-300 ${
            location.pathname === "/payroll-component" &&
            "bg-orange-500 text-white w-full"
          }`}
        >
          <PayrollComponentSVG
            color={`${
              location.pathname === "/payroll-component" ? "white" : "black"
            }`}
            className="ml-5"
          />
          <h1 className="text-sm font-semibold">Payroll Component</h1>
        </NavLink>
        <NavLink
          to={"/payment-schedule"}
          className={`flex gap-1 rounded-md p-3 w-full items-center hover:scale-x-105 hover:cursor-pointer transition-all duration-300 ${
            location.pathname === "/payment-schedule" &&
            "bg-orange-500 text-white w-full"
          }`}
        >
          <PaymentScheduleSVG
            color={`${
              location.pathname === "/payment-schedule" ? "white" : "black"
            }`}
            className="ml-5"
          />
          <h1 className="text-sm font-semibold">Payment Schedule</h1>
        </NavLink>
        <NavLink
          to={"/claims-type"}
          className={`flex gap-1 rounded-md p-3 w-full items-center hover:scale-x-105 hover:cursor-pointer transition-all duration-300 ${
            (location.pathname === "/claims-type" ||
              location.pathname === "/create-claim-type") &&
            "bg-orange-500 text-white w-full"
          }`}
        >
          <ClaimsTypeSVG
            color={`${
              location.pathname === "/claims-type" ||
              location.pathname === "/create-claim-type"
                ? "white"
                : "black"
            }`}
            className="ml-5"
          />
          <h1 className="text-sm font-semibold">Claims Type</h1>
        </NavLink>
      </div>
    </div>
  );
};
const payrollslipEmployeeDropDown = (location) => {
  return (
    <div>
      <div
        className="flex items-center w-full gap-1 p-3 rounded-md hover:cursor-pointer"
        onClick={() => {
          const hidden = document
            .getElementById("payroll-setup")
            .classList.contains("hidden");
          if (hidden) {
            document.getElementById("payroll-setup").classList.remove("hidden");
          } else {
            document.getElementById("payroll-setup").classList.add("hidden");
          }
        }}
      >
        <PayrollSetupSVG color={"black"} />
        <h1 className="text-sm font-semibold">Payroll Setup</h1>
        <ChevronDownSVG />
      </div>

      <div id="payroll-setup" className={`flex flex-col gap-2 mt-2`}>
        <NavLink
          to={"/payroll-component"}
          className={`flex gap-1 rounded-md p-3 w-full items-center hover:scale-x-105 hover:cursor-pointer transition-all duration-300 ${
            location.pathname === "/payroll-component" &&
            "bg-orange-500 text-white w-full"
          }`}
        >
          <PayrollComponentSVG
            color={`${
              location.pathname === "/payroll-component" ? "white" : "black"
            }`}
            className="ml-5"
          />
          <h1 className="text-sm font-semibold">Payroll Component</h1>
        </NavLink>
        <NavLink
          to={"/payment-schedule"}
          className={`flex gap-1 rounded-md p-3 w-full items-center hover:scale-x-105 hover:cursor-pointer transition-all duration-300 ${
            location.pathname === "/payment-schedule" &&
            "bg-orange-500 text-white w-full"
          }`}
        >
          <PaymentScheduleSVG
            color={`${
              location.pathname === "/payment-schedule" ? "white" : "black"
            }`}
            className="ml-5"
          />
          <h1 className="text-sm font-semibold">Payment schedule</h1>
        </NavLink>
      </div>
    </div>
  );
};
const cpfDropDown = (location) => {
  return (
    <div>
      <div
        className="flex items-center w-full gap-1 p-3 rounded-md hover:cursor-pointer"
        onClick={() => {
          const hidden = document
            .getElementById("cpf-configuration-setup")
            .classList.contains("hidden");
          if (hidden) {
            document
              .getElementById("cpf-configuration-setup")
              .classList.remove("hidden");
          } else {
            document
              .getElementById("cpf-configuration-setup")
              .classList.add("hidden");
          }
        }}
      >
        <TicketSVG color={"black"} />
        <h1 className="text-sm font-semibold">CPF Configuration</h1>
        <ChevronDownSVG />
      </div>

      <div id="cpf-configuration-setup" className={`flex flex-col gap-2 mt-2`}>
        <NavLink
          to={"/cpf-configuration"}
          className={`flex gap-1 rounded-md p-3 w-full items-center hover:scale-x-105 hover:cursor-pointer transition-all duration-300 ${
            (location.pathname === "/cpf-configuration" ||
              location.pathname.startsWith("/cpf-configuration-create-edit")) &&
            "bg-orange-500 text-white w-full"
          }`}
        >
          <TicketSVG
            color={`${
              location.pathname === "/cpf-configuration" ||
              location.pathname.startsWith("/cpf-configuration-create-edit")
                ? "white"
                : "black"
            }`}
            className="ml-5"
          />
          <h1 className="text-sm font-semibold">CPF Configuration</h1>
        </NavLink>
        <NavLink
          to={"/shg-funds"}
          className={`flex gap-1 rounded-md p-3 w-full items-center hover:scale-x-105 hover:cursor-pointer transition-all duration-300 ${
            (location.pathname === "/shg-funds" ||
              location.pathname.startsWith("/shg-funds-create-edit")) &&
            "bg-orange-500 text-white w-full"
          }`}
        >
          <TicketSVG
            color={`${
              location.pathname === "/shg-funds" ||
              location.pathname.startsWith("/shg-funds-create-edit")
                ? "white"
                : "black"
            }`}
            className="ml-5"
          />
          <h1 className="text-sm font-semibold">SHG Funds</h1>
        </NavLink>
        <NavLink
          to={"/salary-ceilings"}
          className={`flex gap-1 rounded-md p-3 w-full items-center hover:scale-x-105 hover:cursor-pointer transition-all duration-300 ${
            (location.pathname === "/salary-ceilings" ||
              location.pathname.startsWith("/salary-ceilings-create-edit")) &&
            "bg-orange-500 text-white w-full"
          }`}
        >
          <TicketSVG
            color={`${
              location.pathname === "/salary-ceilings" ||
              location.pathname.startsWith("/salary-ceilings-create-edit")
                ? "white"
                : "black"
            }`}
            className="ml-5"
          />
          <h1 className="text-sm font-semibold">Salary Ceilings</h1>
        </NavLink>
        <NavLink
          to={"/sdl"}
          className={`flex gap-1 rounded-md p-3 w-full items-center hover:scale-x-105 hover:cursor-pointer transition-all duration-300 ${
            (location.pathname === "/sdl" ||
              location.pathname.startsWith("/sdl-create-edit")) &&
            "bg-orange-500 text-white w-full"
          }`}
        >
          <TicketSVG
            color={`${
              location.pathname === "/sdl" ||
              location.pathname.startsWith("/sdl-create-edit")
                ? "white"
                : "black"
            }`}
            className="ml-5"
          />
          <h1 className="text-sm font-semibold">SDL</h1>
        </NavLink>
        <NavLink
          to={"/fwl"}
          className={`flex gap-1 rounded-md p-3 w-full items-center hover:scale-x-105 hover:cursor-pointer transition-all duration-300 ${
            (location.pathname === "/fwl" ||
              location.pathname.startsWith("/fwl-edit")) &&
            "bg-orange-500 text-white w-full"
          }`}
        >
          <TicketSVG
            color={`${
              location.pathname === "/fwl" ||
              location.pathname.startsWith("/fwl-edit")
                ? "white"
                : "black"
            }`}
            className="ms-5"
          />
          <h1 className="text-sm font-semibold">FWL</h1>
        </NavLink>
      </div>
    </div>
  );
};

const Layout = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { currentUser, currentRole } = useSelector((state) => state.loginSlice);
  let { id } = useParams();
  const [apps, setApps] = useState([]);

  const { data: resApp } = useFetchAppsQuery(null, {
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    if (resApp?.data?.data) {
      setApps(resApp.data.data);
    }
  }, [resApp]);

  const onClickApp = (link) => {
    window.location.assign(link);
  };

  const onClickMyAccount = (link) => {
    window.location.assign(`${link}/myAccount`);
  };
  const onClickSwitch = () => {
    dispatch(setRoleReducer());
  };
  const onClickLogout = () => {
    dispatch(setLogoutReducer());
    localStorage.removeItem("accessToken");
    localStorage.removeItem("currentUser");
    localStorage.removeItem("currentRole");

    // using window as we used microservice frontend and need to redirect to workbench
    window.location.assign("/dashboard");
  };
  const adminMenus = [
    {
      label: (
        <NavLink
          to={"/"}
          className={`flex gap-1 rounded-md p-3 w-full items-center hover:scale-x-105 hover:cursor-pointer transition-all duration-300 ${
            location.pathname === "/" && "bg-orange-500 text-white w-full"
          } ${
            matchPath(`/payroll/*`, location.pathname) &&
            "bg-orange-500 text-white w-full"
          } ${
            matchPath(`/leave-excessive/*`, location.pathname) &&
            "bg-orange-500 text-white w-full"
          }`}
        >
          <PayrollSVG
            color={`${
              location.pathname === "/" ||
              matchPath(`/payroll/*`, location.pathname) ||
              matchPath(`/leave-excessive/*`, location.pathname)
                ? "white"
                : "black"
            }`}
          />
          <h1 className="text-sm font-semibold">Payroll</h1>
        </NavLink>
      ),
    },
    {
      label: (
        <NavLink
          to={"/run-payroll"}
          className={`flex gap-1 rounded-md p-3 w-full items-center hover:scale-x-105 hover:cursor-pointer transition-all duration-300 ${
            location.pathname === "/run-payroll" &&
            "bg-orange-500 text-white w-full"
          }`}
        >
          <RunPayrollSVG
            color={`${
              location.pathname === "/run-payroll" ? "white" : "black"
            }`}
          />
          <h1 className="text-sm font-semibold">Run Payroll</h1>
        </NavLink>
      ),
    },
    {
      label: reportsDropDown(location),
    },
    {
      label: payrollslipDropDown(location),
    },
    {
      label: cpfDropDown(location),
    },
  ];
  const employeeMenus = [
    {
      label: (
        <NavLink
          to={"/"}
          className={`flex gap-1 rounded-md p-3 w-full items-center hover:scale-x-105 hover:cursor-pointer transition-all duration-300 ${
            location.pathname === "/" && "bg-orange-500 text-white w-full"
          } ${
            matchPath(`/payroll/*`, location.pathname) &&
            "bg-orange-500 text-white w-full"
          } ${
            matchPath(`/leave-excessive/*`, location.pathname) &&
            "bg-orange-500 text-white w-full"
          }`}
        >
          <PayrollSVG
            color={`${
              location.pathname === "/" ||
              matchPath(`/payroll/*`, location.pathname) ||
              matchPath(`/leave-excessive/*`, location.pathname)
                ? "white"
                : "black"
            }`}
          />
          <h1 className="text-sm font-semibold">Payroll</h1>
        </NavLink>
      ),
    },
    {
      label: reportsEmployeeDropDown(location),
    },
    {
      label: payrollslipEmployeeDropDown(location),
    },
  ];
  const getTitle = () => {
    switch (location.pathname) {
      case "/":
        return "Payroll";
      case "/run-payroll":
        return "Run Payroll";
      case `/run-payroll/${id}`:
        return "Run Payroll";
      case "/payroll-report":
        return "Payroll Report";
      case `/payroll-report/detail`:
        return "Payroll Report";
      case `/payroll-report/detail/${id}`:
        return "Payroll Report";

      case "/employee-payslip":
        return "Employee Payslip";

      case "/payroll-component":
        return "Payroll Component";
      case "/payment-schedule":
        return "Payment Schedule";
      case "/claims-type":
        return "Claims Type";
      case "/cpf-configuration":
        return "Central Provident Fund Board (CPFB)";
      case `/cpf-configuration-create-edit/${id}`:
        return "Edit Central Provident Fund Board (CPFB)";
      case `/cpf-configuration-create-edit`:
        return "Create Central Provident Fund Board (CPFB)";
      case "/claim-detail":
        return "Claim Detail";
      case "/salary-ceilings":
        return "Salary Ceilings";
      case "/sdl":
        return "Skills Development Levy";
      case `/sdl-create-edit/${id}`:
        return "Skills Development Levy";
      case `/sdl-create-edit`:
        return "Skills Development Levy";
      case `/fwl`:
        return "Singapore Foreign Worker Levy";
      case `/fwl-edit`:
        return "Add Singapore Foreign Worker Levy";
      case `/employee-payslip/detail/${id}`:
        return "Payslip Details";
      case `/fwl-edit/${id}`:
        return "Edit Singapore Foreign Worker Levy";
      case `/create-claim-type`:
        return "Claims Type";
      case `/salary-ceilings-create-edit`:
        return "Salary Ceilings Details";
      case `/salary-ceilings-create-edit/${id}`:
        return "Salary Ceilings Details";
      case `/shg-funds`:
        return "SHG Funds";
      case `/detail/${id}`:
        return "Claims Detail";
      case `/shg-funds-create-edit`:
        return "SHG Funds";
      case `/shg-funds-create-edit/${id}`:
        return "SHG Funds";

      case `/shg-funds/${id}`:
        return "SHG Funds";

      default:
        return "Test";
    }
  };
  const switchRole = currentUser?.role.filter((d) => d !== currentRole)[0];

  return (
    <div className="grid grid-cols-[300px_1fr] h-screen w-full">
      <div className="w-full overflow-y-auto z-[9] bg-[#DAEDE3]">
        <Sidebar
          menuItems={
            currentRole?.toLowerCase() === "employee" ||
            currentRole.toLowerCase() === "supervisor"
              ? employeeMenus
              : adminMenus
          }
          className={"h-fit bg-[#DAEDE3]"}
          onLogoClick={() => navigate("/")}
          version={pkg.version}
        />
      </div>

      <div className="flex flex-col w-full p-5 overflow-auto">
        <div className="p-1">
          {apps.length > 0 && (
            <Header
              title={getTitle()}
              apps={apps}
              switchDisabled={currentUser?.role?.length < 2}
              userData={{
                name: currentUser?.userName,
                role: currentRole,
                language: "English",
                switchRole: switchRole,
                image:
                  currentUser?.photo?.length > 0
                    ? currentUser?.photo[0].link
                    : "",
              }}
              onSwitch={onClickSwitch}
              onClickMyAccount={onClickMyAccount}
              onClickLogout={onClickLogout}
              onClickApp={onClickApp}
            />
          )}
        </div>

        <Outlet />
      </div>
    </div>
  );
};

export default Layout;
