import * as Yup from "yup";

/**
 * @typedef {Object} ClaimDetail
 * @property {string} amount - The amount of the claim.
 * @property {string} claimTypeId - The ID of the claim type.
 * @property {string} claimName - The claimName of the claim type.
 */

/**
 * @typedef {Object} Status
 * @property {string} bySupervisor - The status set by the supervisor.
 * @property {string} byHr - The status set by HR.
 */

/**
 * @typedef {Object} RequestClaims
 * @property {string} uId - Unique ID, required.
 * @property {string} userId - The user ID of the requester.
 * @property {string} claimName - The name of the claim.
 * @property {Date} claimDate - The date of the claim.
 * @property {string} description - A description of the claim.
 * @property {string} totalClaim - The total amount of the claim.
 * @property {boolean} [isDeleted=false] - Flag indicating if the claim is deleted.
 * @property {Status} [status] - The status of the claim.
 * @property {string} [comments] - Additional comments about the claim.
 * @property {string} [attachments] - Any attachments related to the claim.
 * @property {ClaimDetail[]} claimDetails - Details of each claim.
 */

/**
 * @typedef {Object} Claim
 * @property {string} name - The name of the employee.
 * @property {string} img - The URL of the employee's avatar image.
 * @property {string} employeeId - The unique identifier for the employee.
 * @property {string} position - The position of the employee within the company.
 * @property {string} requestDate - The date the claim was requested.
 * @property {string} claimName - The name of the claim.
 * @property {string} totalClaim - The total amount claimed.
 * @property {string} bySupervisor - The approval status from the supervisor.
 * @property {string} byHRAdmin - The approval status from the HR admin.
 * @property {string} status - The overall status of the claim.
 * @property {string} uId - The unique identifier for the claim.
 */

export const PayrollRunSetting = Yup.object().shape({
  label: Yup.string().min(1).required("Label is required."),
  paymentDate: Yup.string().required("Payment date is required."),
  startDate: Yup.string().required("Start date is required."),
  endDate: Yup.string()
    .test(
      "is-greater",
      "End date must be later than start date.",
      function (value) {
        const { startDate } = this.parent;
        return startDate ? value > startDate : true;
      },
    )
    .required("End date is required."),
  location: Yup.string().required("Location is required."),
  employmentStatus: Yup.string().required("Employment status is required."),
  // selectedEmployees: Yup.array()
  //   .min(1, "At least one employee must be selected.")
  //   .required("Selected employees are required."),
});

/**
 * Schema for validating request claims.
 * @type {import('yup').ObjectSchema<RequestClaims>}
 */
export const RequestClaimsSchema = Yup.object().shape({
  uId: Yup.string().required("User ID is required."),
  userId: Yup.string().required("User ID is required."),
  claimName: Yup.string().required("Claim name is required."),
  claimDate: Yup.string().required("Claim date is required."),
  description: Yup.string(),
  totalClaim: Yup.string().required("Total claim amount is required."),
  isDeleted: Yup.boolean().default(false),
  status: Yup.object().shape({
    bySupervisor: Yup.string().default("pending"),
    byHr: Yup.string().default("pending"),
  }),
  comments: Yup.string(),
  attachments: Yup.string(),
  claimDetails: Yup.array().of(
    Yup.object().shape({
      amount: Yup.string().required("Claim amount is required."),
      claimTypeId: Yup.string().required("Claim type ID is required."),
      claimName: Yup.string().required("Claim name is required."),
    }),
  ),
});

/**
 * Yup validation schema for the provided data structure.
 *
 * @typedef {Object} Location
 * @property {string} name - The name of the location (e.g., "bandung").
 * @property {string} id - The unique ID of the location (UUID format).
 *
 * @typedef {Object} AppendAdditionalOptionalItem
 * @property {string} idUser - The user ID (UUID format).
 * @property {"earning" | "deduction"} type - The type of the entry.
 * @property {"fixed" | "variable"} status - The status of the entry.
 * @property {string} name - The name of the entry.
 * @property {number} amount - The amount associated with the entry.
 *
 * @typedef {Object} ValidationSchema
 * @property {Date} startDate - The start date of the period.
 * @property {Date} endDate - The end date of the period.
 * @property {Date} paymentDate - The payment date.
 * @property {string} label - The label or description for the entry.
 * @property {Location} location - Location details.
 * @property {string} employmentStatus - Employment status (e.g., "option2").
 * @property {"draft" | "final"} status - The status of the entry.
 * @property {AppendAdditionalOptionalItem[]} appendAdditionalOptional - Optional additional entries.
 * @property {string[]} userIds - List of user IDs (UUID format).
 */

/**
 * Yup validation schema for Payroll.
 * @type {Yup.ObjectSchema<ValidationSchema>}
 */
export const payrollSchema = Yup.object().shape({
  startDate: Yup.date().required("Start date is required."),
  endDate: Yup.date().required("End date is required."),
  paymentDate: Yup.date().required("Payment date is required."),
  label: Yup.string().required("Label is required."),
  location: Yup.object({
    name: Yup.string().required("Location name is required."),
    id: Yup.string()
      .uuid("Location ID must be a valid UUID.")
      .required("Location ID is required."),
  }).required("Location is required."),
  employmentStatus: Yup.string().required("Employment status is required."),
  status: Yup.string().oneOf(["draft", "paid"]).required("Status is required."),
  appendAdditionalOptional: Yup.array().of(
    Yup.object().shape({
      idUser: Yup.string()
        .uuid("User ID must be a valid UUID.")
        .required("User ID is required."),
      type: Yup.string()
        .oneOf(["earning", "deduction"])
        .required("Type is required."),
      status: Yup.string()
        .oneOf(["fixed", "percent"])
        .required("Status is required."),
      name: Yup.string().required("Name is required."),
      amount: Yup.number().required("Amount is required."),
    }),
  ),
  userIds: Yup.array()
    .of(
      Yup.string()
        .uuid("User ID must be a valid UUID.")
        .required("User ID is required."),
    )
    .required("User IDs are required."),
});

/**
 * @typedef {Object} Additional
 * @property {string} amount - The monetary amount in string format, e.g., 'Rp 0'.
 * @property {string} name - The name of the additional item.
 * @property {string} componentType - The type of component associated with the additional item.
 * @property {string} amountType - The type of amount (e.g., fixed or variable).
 */

/**
 * @typedef {Object} UserAdditional
 * @property {string} userId - The unique identifier for the user.
 * @property {Additional[]} listAdditional - A list of additional items.
 */

export const AdditionalSchema = Yup.object().shape({
  userId: Yup.string().required("User ID is required"),
  listAdditional: Yup.array().of(
    Yup.object().shape({
      amount: Yup.string().required("Amount is required"),
      name: Yup.string().required("Name is required"),
      componentType: Yup.string().required("Component Type is required"),
      amountType: Yup.string().required("Amount Type is required"),
    }),
  ),
});
