import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import {
  useFetchClaimsIdQuery,
  useUpdateClaimsStatusMutation,
} from "store/api/claimsApi";
import {
  Navigate,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { Accordion, Button } from "@bluesilodev/timhutcomponents";
import { useFetchEmployeeByIdMutation } from "store/api/employeeComponentApi";
import dayjs from "dayjs";
import { FileSizeSVG } from "assets/icons/FileSizeSVG";
import { FileDownloadSVG } from "assets/icons/FileDownloadSVG";
const Detail = () => {
  const { currentRole, currentUser } = useSelector((state) => state.loginSlice);

  let { id } = useParams();
  const navigate = useNavigate();
  const [role] = React.useState(currentRole || "user");

  /**
   * @type {[
   *   (id: number|string) => Promise<{data:{data:User[]}}>,
   * ]}
   */
  const [requestEmployeeUser] = useFetchEmployeeByIdMutation();
  const [requestClaimStatus] = useUpdateClaimsStatusMutation();
  /**
   * @type {[User]}
   */
  const [user, setUser] = useState("");
  /**
   * @type {{data:{data:import("type/typePayroll").RequestClaims}}}
   */
  const { data } = useFetchClaimsIdQuery(id, {
    refetchOnMountOrArgChange: true,
    skip: false,
  });
  /**
   * @type {import("type/typePayroll").RequestClaims}
   */

  const detailEmployee = data?.data || "";
  useEffect(() => {
    requestEmployeeUser(detailEmployee?.userId).then((r) => {
      if (r?.data?.data?.length > 0) {
        setUser(r?.data?.data[0]);
      }
    });
  }, [detailEmployee]);

  /**
   * @type {[import("type/typePayroll").Claim, React.Dispatch<import("type/typePayroll").Claim>]} state
   * */

  const updateStatus = async (status) => {
    try {
      let result = null;
      if (role?.toLowerCase() === "admin") {
        result = { admin: status, id };
      } else if (role?.toLowerCase() === "supervisor") {
        if (status) {
          result = { supervisor: status, id };
        } else {
          result = { supervisor: status, admin: status, id };
        }
      }
      await requestClaimStatus(result);
      navigate("/");
    } catch (e) {
      console.warn("payroll", e);
    }
  };
  const fileAttachments = detailEmployee?.attachments;
  const handleDownload = async (name) => {
    try {
      const link = document.createElement("a");
      link.href = name;
      link.setAttribute("download", "image.jpg"); // Nama file yang akan diunduh
      link.setAttribute("target", "_blank"); // Nama file yang akan diunduh
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.log("error", error);
    }
  };

  const validation = useMemo(() => {
    const isNotEmployee = role?.toLowerCase() !== "employee";
    const isAdmin = role?.toLowerCase() === "admin";
    const isSupervisor = role?.toLowerCase() === "supervisor";
    const isAdminOrSupervisor = isAdmin || isSupervisor;

    // For admin, we set reportTo to true without checking user?.reportTo
    const isUserReportedToCurrentUser =
      isAdmin || user?.reportTo === "" || currentUser?.uId === user?.reportTo;

    // Validation will be true if the user is not an employee, is an admin or supervisor, and reportTo is valid
    return isNotEmployee && isAdminOrSupervisor && isUserReportedToCurrentUser;
  }, [role, currentUser, user]);

  const statusApproved = useMemo(() => {
    return role?.toLowerCase() === "admin"
      ? detailEmployee?.status?.byHr
      : detailEmployee?.status?.bySupervisor;
  }, [detailEmployee?.status, role]);
  if (!id) {
    return <Navigate to="/" replace />;
  }
  return (
    <div className="h-max w-full mt-5 space-y-5">
      {statusApproved === "approved" ? (
        <div className="flex justify-end">
          <div
            className={` ${statusApproved === "approved" && "bg-green-200"}
             gap-4 text-center font-bold p-4 border rounded-md capitalize w-[200px]`}
          >
            {statusApproved}
          </div>
        </div>
      ) : statusApproved === "rejected" ? (
        <div className="flex justify-end">
          <div
            className={` ${
              statusApproved === "rejected" && "bg-red-400"
            } gap-4 p-4
            text-center font-bold w-[200px] border rounded-md capitalize `}
          >
            {statusApproved}
          </div>
        </div>
      ) : (
        validation && (
          <div className="flex justify-end">
            <div className="flex gap-4">
              <Button
                className={"w-[200px]"}
                backgroundColor={"#ef4444"}
                borderColor={"#ef4444"}
                onClick={() => updateStatus(false)}
                label={
                  <div className="flex px-4 gap-1 text-white capitalize">
                    <div>reject</div>
                  </div>
                }
              />
              <Button
                className={"w-[200px]"}
                backgroundColor={"#22c55e"}
                borderColor={"#22c55e"}
                onClick={() => updateStatus(true)}
                label={
                  <div className="flex px-4 gap-1 text-white capitalize">
                    <div>approved</div>
                  </div>
                }
              />
            </div>
          </div>
        )
      )}
      <Accordion
        title={<div className="p-2 capitalize">Employee Details</div>}
        icons={[]}
        children={
          <div className="grid grid-cols-[auto_1fr] gap-4">
            <img
              src={user?.photo?.length > 0 ? user?.photo[0]?.link : ""}
              className="rounded-full w-[200px] h-[200px] object-contain"
            />
            <div className="grid grid-cols-3">
              <div className="rounded-md p-4 bg-gray-200 h-fit">
                <div className="capitalize">claims status</div>
                <div className="capitalize">
                  {role?.toLowerCase() === "admin"
                    ? detailEmployee?.status?.byHr
                    : detailEmployee?.status?.bySupervisor}
                </div>
              </div>
              <div className="grid grid-cols-3 row-start-2 col-span-full gap-3">
                <div className="rounded-md p-4 bg-gray-100 h-fit">
                  <div className="capitalize">first name</div>
                  <div className="capitalize">{user?.firstName}</div>
                </div>
                <div className="rounded-md p-4 bg-gray-100 h-fit ">
                  <div className="capitalize">last name</div>
                  <div className="capitalize">{user?.lastName || "-"}</div>
                </div>
                <div className="rounded-md p-4 bg-gray-100 h-fit ">
                  <div className="capitalize">employee id</div>
                  <div className="capitalize">{user?.employeeID}</div>
                </div>
                <div className="rounded-md p-4 bg-gray-100 h-fit ">
                  <div className="capitalize">location</div>
                  <div className="capitalize">{user?.locations?.address}</div>
                </div>{" "}
                <div className="rounded-md p-4 bg-gray-100 h-fit ">
                  <div className="capitalize">department</div>
                  <div className="capitalize">
                    {user?.userInformation?.employeementDetail?.departments}
                  </div>
                </div>
                <div className="rounded-md p-4 bg-gray-100 h-fit ">
                  <div className="capitalize">job position</div>
                  <div className="capitalize">
                    {user?.userInformation?.employeementDetail?.jobPosition}
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      />
      <Accordion
        title={<div className="p-2 capitalize">claims Details</div>}
        icons={[]}
        children={
          <>
            <div className="capitalize font-semibold">leave records</div>
            <div className="grid grid-cols-3 gap-4">
              {detailEmployee?.claimDetails?.map((d, i) => {
                return (
                  <div
                    className="grid grid-cols-2 gap-4 col-span-2"
                    key={i + "-id-claims"}
                  >
                    <div className="rounded-md p-4 bg-gray-100 h-fit">
                      <div className="capitalize">claims items</div>
                      <div className="capitalize">{d?.claimName}</div>
                    </div>
                    <div className="rounded-md p-4 bg-gray-100 h-fit">
                      <div className="capitalize">amount</div>
                      <div className="capitalize">{d?.amount}</div>
                    </div>
                  </div>
                );
              })}
              <div className="grid grid-cols-3 gap-4 col-span-full">
                <div className="rounded-md p-4 bg-gray-100 h-fit">
                  <div className="capitalize">claims name</div>
                  <div className="capitalize">
                    {detailEmployee?.claimName || ""}
                  </div>
                </div>
                <div className="rounded-md p-4 bg-gray-100 h-fit">
                  <div className="capitalize">Date</div>
                  <div className="capitalize">
                    {dayjs(detailEmployee?.claimDate).format("DD MMMM YYYY")}
                  </div>
                </div>
                <div className="rounded-md p-4 bg-gray-100 h-fit">
                  <div className="capitalize">total claims</div>
                  <div className="capitalize">{detailEmployee?.totalClaim}</div>
                </div>
              </div>
              <div className="rounded-md p-4 bg-gray-100 h-[150px] col-span-full">
                <div className="capitalize">description</div>
                <div className="capitalize">{detailEmployee?.description}</div>
              </div>
              <div className="rounded-md flex gap-4 justify-between p-4 bg-gray-100 h-fit col-span-full ">
                <div className="flex gap-4">
                  <FileSizeSVG />
                  <div>
                    {
                      fileAttachments?.split("/")[
                        fileAttachments?.split("/")?.length - 1
                      ]
                    }
                  </div>
                </div>
                <button onClick={() => handleDownload(fileAttachments)}>
                  <FileDownloadSVG color={"#000"} />
                </button>
              </div>
            </div>
          </>
        }
      />
    </div>
  );
};

export default Detail;
