import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Accordion,
  InputText,
  InputDate,
  ToggleSwitch,
  ModalDialog,
} from "@bluesilodev/timhutcomponents";
import SaveIconSVG from "assets/icons/SaveIconSVG/SaveIconSVG";

import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { useAlertApi } from "services/alert-api";
import UserAddIcon from "assets/icons/UserAddSVG/UserAdd";
import { parse } from "date-fns";
import dayjs from "dayjs";
import { SdlAddEditDataTable } from "components/common/customTables";
import { SdlSchemaYup, SdlValidationSchema } from "type/typeSdl";
import { cleanNumberInput } from "utils/numberUtils";
import { useAddSdlMutation, useUpdateSdlMutation } from "store/api/sdlApi";
/**
 * Modal component for adding or editing data.
 *
 * @param {Object} props - The properties object.
 * @param {function(*):*} [props.setIsShow] - Function to toggle visibility or set a flag based on the provided data. Defaults to a function returning the input data.
 * @param {function(*):*} [props.save] - Function to save the provided data. Defaults to a function returning the input data.
 * @param {Object} [props.edit] - The edit object containing employee information and financial data. Defaults to an empty employee with default values.
 * @param {string} [props.edit.employee=""] - The name of the employee.
 * @param {number} [props.edit.twm=0] - Total work months or another related metric.
 * @param {number} [props.edit.sdlPayable=0] - SDL payable or other financial metric.
 *
 * @returns {JSX.Element} The rendered modal component.
 */
const ModalAddEdit = ({
  setIsShow = (data) => data,
  save = (data) => data,
  edit = {
    twm: 0,
    sdlPayable: 0,
  },
}) => {
  const { setFieldValue, values, handleSubmit, errors, resetForm, setValues } =
    useFormik({
      initialValues: {
        twm: 0,
        sdlPayable: 0,
      },
      validationSchema: SdlValidationSchema,
      onSubmit: async (values) => {
        try {
          save(values);
          setIsShow(false);
          resetForm();
        } catch (error) {
          console.error("error", error);
        }
      },
    });
  useEffect(() => {
    resetForm();
  }, []);
  useEffect(() => {
    if (edit) {
      setValues(edit);
    }
  }, [edit]);

  return (
    <div className="grid items-center grid-cols-2 gap-4 my-5 ">
      <InputText
        value={values.twm}
        error={errors.twm}
        setFieldValue={setFieldValue}
        onChange={(d) => {
          let value = d?.target?.value;
          value = cleanNumberInput(value);
          setFieldValue(`twm`, value || "0");
        }}
        name={"twm"}
        id={"twm"}
        title="Total wages for the calendar month"
      />
      <InputText
        value={values.sdlPayable}
        error={errors.sdlPayable}
        setFieldValue={setFieldValue}
        onChange={(d) => {
          let value = d?.target?.value;
          value = cleanNumberInput(value);
          setFieldValue(`sdlPayable`, value || "0");
        }}
        name={"sdlPayable"}
        id={"sdlPayable"}
        title="SDL Payable Secondary"
      />
      <div className="flex mb-5 col-span-full">
        <div className="grid w-full grid-cols-2 gap-4">
          <div className="flex w-full">
            <Button
              className={"w-full"}
              onClick={() => setIsShow(false)}
              label={
                <div className="flex gap-1 px-4">
                  <div>Cancel</div>
                </div>
              }
            />
          </div>
          <div className="flex w-full">
            <Button
              className={"w-full"}
              style="solid"
              onClick={() => handleSubmit()}
              label={"Create SDL"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
const AddEditSdl = () => {
  const location = useLocation();
  const { state } = location;
  const params = useParams();
  /**
   * @typedef {Object} PayrollShgSchema
   * @property {import("type/typeCpf").PayrollShgSchema} data
   */
  /**
   * @type {PayrollShgSchema}
   */
  const [request, result] = useAddSdlMutation();
  const [requestUpdate, resultUpdate] = useUpdateSdlMutation();
  const [isShow, setIsShow] = useState(false);
  const [edit, setEdit] = useState({ id: null, data: null });
  const navigate = useNavigate();

  const {
    handleChange,
    setFieldValue,
    values,
    handleSubmit,
    errors,
    resetForm,
    setValues,
    handleBlur,
    touched,
  } = useFormik({
    initialValues: {
      sdlName: "",
      sdlStartDate: "",
      sdlEndDate: "",
      sdlRate: 0.25,
      sdlStatus: false,
      sdlList: [],
    },
    validationSchema: SdlSchemaYup,
    onSubmit: async (values) => {
      try {
        const parseValues = { ...values };

        if (
          parseValues?.__v !== "" &&
          parseValues?.__v !== undefined &&
          params?.id
        ) {
          delete parseValues.__v;
        }
        if (parseValues?.createdAt && params?.id) {
          delete parseValues.createdAt;
        }
        if (parseValues?.updatedAt && params?.id) {
          delete parseValues.updatedAt;
        }
        parseValues.sdlStartDate = parse(
          parseValues?.sdlStartDate,
          "dd/MM/yyyy",
          new Date(),
        );
        parseValues.sdlEndDate = parse(
          parseValues?.sdlEndDate,
          "dd/MM/yyyy",
          new Date(),
        );
        values.sdlRate = parseFloat(values?.sdlRate);
        if (params?.id) {
          await requestUpdate({ id: params?.id, ...parseValues });
        } else {
          delete parseValues._id;
          await request(parseValues);
        }

        navigate("/sdl");
      } catch (error) {
        console.error("error", error);
      }
    },
  });

  /**
   * @param {''salaryCeilingList' } status
   */

  const handleDelete = (id) => {
    const tempSdl = [...values.sdlList];
    if (id !== null && id !== undefined) {
      tempSdl.splice(id, 1);
    }
    setFieldValue("sdlList", tempSdl);
  };
  /**
   * @function handleEdit
   * @param {import("type/typeCpf").EmployeeContribution} data
   */
  const handleEdit = (data) => {
    setEdit(data);
    setIsShow(true);
  };
  useAlertApi(params?.id ? resultUpdate : result);
  useEffect(() => {
    if (params?.id) {
      setFieldValue("_id", params?.id);
    }
  }, [params]);
  useEffect(() => {
    if (state) {
      const data = state;
      resetForm();
      const date = {
        sdlStartDate: dayjs(data.sdlStartDate).format("DD/MM/YYYY"),
        sdlEndDate: dayjs(data.sdlEndDate).format("DD/MM/YYYY"),
      };
      setValues({
        ...data,
        ...date,
      });
    }
  }, [state, params]);
  return (
    <div className="w-full mt-5 space-y-4">
      <div className="w-[100%]  flex justify-end">
        <Button
          style="solid"
          onClick={() => handleSubmit()}
          label={
            <div className="flex gap-1 px-4">
              <SaveIconSVG color={"white"} />
              <div>{params?.id ? "Update" : "Save"}</div>
            </div>
          }
        />
      </div>
      <div className="flex w-full mt-5 ">
        <Accordion
          title={`Skills Development Levy Details`}
          children={
            <div className="grid grid-cols-2 gap-4 mt-5">
              <div className="col-span-full">
                <InputText
                  value={values.sdlName}
                  error={errors.sdlName && touched.sdlName && errors.sdlName}
                  setFieldValue={setFieldValue}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  required
                  name={"sdlName"}
                  id={"sdlName"}
                  title="Skills Development Levy Name"
                  placeholder="Enter skills development levy name"
                />
              </div>

              <InputDate
                name={"sdlStartDate"}
                id={"sdlStartDate"}
                label="Start Date Period"
                setFieldValue={setFieldValue}
                value={values.sdlStartDate}
                errors={
                  errors.sdlStartDate &&
                  touched.sdlStartDate &&
                  errors.sdlStartDate
                }
                onChange={handleChange}
                required
                onBlur={handleBlur}
              />
              <InputDate
                name={"sdlEndDate"}
                id={"sdlEndDate"}
                label="End Date Period"
                errors={errors.sdlEndDate}
                setFieldValue={setFieldValue}
                value={values.sdlEndDate}
                onChange={handleChange}
                required
                onBlur={handleBlur}
              />
              <div className="col-span-full">
                <InputText
                  value={values.sdlRate}
                  error={errors.sdlRate && touched.sdlRate && errors.sdlRate}
                  onBlur={handleBlur}
                  onChange={(d) => {
                    let value = d?.target?.value;
                    value = cleanNumberInput(value);
                    if (d?.target?.value === "") {
                      setFieldValue("sdlRate", 0);
                    } else {
                      setFieldValue("sdlRate", value);
                    }
                  }}
                  name={"sdlRate"}
                  id={"sdlRate"}
                  required
                  title="Sdl Rate"
                />
              </div>
              <ToggleSwitch
                value={values?.sdlStatus}
                onChange={(e) => {
                  setFieldValue("sdlStatus", !values.sdlStatus);
                }}
                name="Active"
                label="Active"
              />
            </div>
          }
        />
      </div>
      <div>
        <ModalDialog
          show={isShow}
          className={"min-w-[1000px] w-[90vw] h-auto"}
          onClose={() => setIsShow(false)}
          title={`${edit?.data ? "Edit" : "Create"} Skills Development Levy`}
          children={
            <ModalAddEdit
              edit={edit.data}
              save={(d) => {
                const tempSdl = [...values.sdlList];
                if (edit?.id !== null && edit?.id >= 0) {
                  tempSdl[edit?.id] = d;
                } else {
                  tempSdl.push(d);
                }
                setFieldValue("sdlList", tempSdl);
                setEdit({ id: null, data: null });
              }}
              setIsShow={setIsShow}
            />
          }
        />
        <Accordion
          title={`Skills Development Levy `}
          children={
            <div>
              <SdlAddEditDataTable
                dataTable={values?.sdlList ?? []}
                handleDelete={handleDelete}
                handleEdit={handleEdit}
              />
              <Button
                onClick={() => setIsShow(true)}
                className={"w-full mt-3 justify-self-end"}
                backgroundColor={"#DD7224"}
                label={
                  <div className="flex gap-1 p-2 text-white capitalize">
                    <UserAddIcon color={"#fff"} />
                    <div>
                      <div>Add Skills Development Levy</div>
                    </div>
                  </div>
                }
              />
            </div>
          }
        />
      </div>
    </div>
  );
};

export default AddEditSdl;
