import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import AppRouter from "routes";

import AlertMessage from "components/alertMessage";
import { setLoginReducer } from "./store/slice/userLoginSlice";
import { jwtDecode } from "jwt-decode";

const App = ({ props }) => {
  const dispatch = useDispatch();
  const { currentUser, currentRole, token } = useSelector(
    (state) => state?.loginSlice,
  );
  useEffect(() => {
    const localStorageAccessToken = localStorage.getItem("accessToken");
    const localStorageCurrentRole = localStorage.getItem("currentRole");
    if (localStorageAccessToken) {
      const user = jwtDecode(localStorageAccessToken);

      if (!localStorageCurrentRole) {
        localStorage.setItem("currentRole", user?.role[0]);
      }
      dispatch(
        setLoginReducer({
          currentUser: user,
          token: localStorageAccessToken,
          currentRole: localStorageCurrentRole ?? currentRole ?? user?.role[0],
        }),
      );
    } else {
      if (window.__POWERED_BY_QIANKUN__) {
        window.location.href = "/";
      }
    }
  }, []);

  useEffect(() => {
    if (typeof props?.setGlobalState === "function") {
      props.setGlobalState({
        currentUser: currentUser,
        accessToken: token,
        currentRole: currentRole,
      });
    }
    console.log("qiankun global state update");
  }, [props, currentUser, token, currentRole]);

  return (
    <>
      <AppRouter />
      <AlertMessage />
    </>
  );
};

export default App;
