import axios from "axios";
// import store from "store";
import { store } from "store";
import { setLogoutReducer } from "store/slice/userLoginSlice";

export const getToken = () => localStorage.getItem("accessToken");

export const axiosBaseQuery =
  ({ baseUrl } = { baseUrl: "" }) =>
  /**
   * Executes an Axios request.
   *
   * @param {Object} request - The request configuration object.
   * @param {string} request.url - The URL for the request (relative to baseUrl).
   * @param {import("axios").Method} request.method - The HTTP method (GET, POST, etc.).
   * @param {Object} [request.body] - The request payload for POST/PUT requests.
   * @param {Object} [request.params] - The query parameters for the request.
   * @param {import("axios").RawAxiosRequestHeaders} [request.headers] - The headers for the request.
   * @param {import("axios").ResponseType} [request.responseType] - The type of response expected (e.g., 'json', 'blob').
   * @returns {Promise<{ data: any }>} A promise that resolves to the response data.
   * @throws Will throw an error if the Axios request fails.
   */
  async ({ url, method, body, params, headers, responseType }) => {
    try {
      /**
       * @type {import("axios").AxiosRequestConfig}
       */
      let option = {
        url: baseUrl + url,
        method,
        data: body,
        params,
        headers: { ...headers, Authorization: `Bearer ${getToken() || ""}` },
      };
      if (responseType) {
        option = { ...option, responseType };
      }
      const result = await axios(option);
      return { data: result.data };
    } catch (axiosError) {
      const err = axiosError;
      if (
        axiosError.response &&
        [401, 403].includes(axiosError.response.status)
      ) {
        // console.log("err", err);
        localStorage.clear();
        store.dispatch(setLogoutReducer());
        setTimeout(() => window.location.assign("/dashboard/login"), 100);
      }
      console.error({
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      });
    }
  };
